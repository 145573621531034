<div class="row" id="admin-member-header">
  <div class="col-9 row">
    <div class="col-6">
      <h3>Members</h3>
    </div>
    <div class="col-6 text-right">
      <button  class="btn btn-info"  id="scores" (click)="addMember()">Add Member</button>
    </div>
  </div>
</div>
<div class="row"  *ngIf='hasPlayers'>
  <div class="col-9">
    <table id="grid-admin" class="table table-bordered table-striped table-hover table-sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>GHIN#</th>
          <th>Handicap</th>
          <th>Status</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let player of players  | playerSort: "lastName"'>
          <td class="player-nl">{{player.firstName}} {{player.lastName}}</td>
          <td>{{player.ghin}}</td>
          <td>{{player.handicap}}</td>
          <td>{{getPlayerStatusText(player.isActive)}}</td>
          <td align="center"><a class="alter-link" [routerLink]="['../admin-member-edit', player._id]">Edit</a></td>
          <td align="center"><a class="alter-link" href="#" (click)="deletePlayer($event, player._id, content)">Delete</a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-3">
  </div>
</div>
<div class="row admin-spinner" *ngIf='!hasPlayers'>
  <div class="spinner-border loader" role="status">
    <span class="sr-only loader">Loading...</span>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span>Are you sure you want to delete user</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('delte')">Delete</button>
  </div>
</ng-template>
