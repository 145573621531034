import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common'; 
@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.css']
})
export class RulesComponent implements OnInit, OnDestroy {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.document.body.classList.remove('body');
    this.document.body.classList.add('body_alter');
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('body_alter');
    this.document.body.classList.add('body');
  }

}
