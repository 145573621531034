import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './modules/about/about.component';
import { CoursesComponent } from './modules/courses/courses.component';
import { HomeComponent } from './modules/home/home.component';
import { LoginComponent } from './modules/login/login.component';
import { PlayersComponent } from './modules/players/players.component';
import { ScheduleComponent } from './modules/schedule/schedule.component';
import { AuthGuardService } from './services/auth-guard.service';
import { StandingsComponent } from './modules/standings/standings.component';
import { TournamentDetailComponent } from './modules/tournaments/tournament-detail/tournament-detail.component';
import { TournamentsComponent } from './modules/tournaments/tournaments.component';
import { PlayerDetailComponent } from './modules/players/player-detail/player-detail.component';
import { StandingsInfoResolver, TournamentInfoResolver } from './app-routing.service';
import { RulesComponent } from './modules/rules/rules.component';
import { JoinComponent } from './modules/join/join.component';

const routes: Routes = [
  { path: 'home', resolve: { standingsData: StandingsInfoResolver}, component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'schedule', component: ScheduleComponent },
  { path: 'players', component: PlayersComponent },
  { path: 'playerDetails', component: PlayerDetailComponent, canActivate: [AuthGuardService]  },
  { path: 'tournaments', component: TournamentsComponent },
  { path: 'tournamentDetails/:tournamentId', resolve: { tournamentData: TournamentInfoResolver}, component: TournamentDetailComponent },
  { path: 'courses', component: CoursesComponent },
  { path: 'standings', resolve: { standingsData: StandingsInfoResolver}, component: StandingsComponent },
  { path: 'rules', component: RulesComponent },
  { path: 'login', component: LoginComponent },
  { path: 'howtojoin', component: JoinComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
