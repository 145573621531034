import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { PlayerSort } from 'src/app/pipes/player-sort.pipe';
import { ScoreSort } from 'src/app/pipes/score-sort.pipe';



@NgModule({
  declarations: [
    PlayerSort,
    ScoreSort
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    PlayerSort,
    ScoreSort
  ],
  providers: [
    PlayerSort,
    ScoreSort
  ]
})
export class SharedModule { }
