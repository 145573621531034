import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { IPlayer, IRound, ITournament, PlayerStanding } from './models/viewModels';
import { TkcApiService } from './services/tkc-api.service';
import { TkcDataService } from './services/tkc-data.service';

@Injectable()
export class TournamentInfoResolver implements Resolve<Observable<ITournament>> {
  constructor(
    private router: Router,
    private tkcApi: TkcApiService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITournament> {
    const tournamentId = route.params.tournamentId;
    if (tournamentId == null) {
      return of(null);
    }

    return this.tkcApi.getTournamentDetails(tournamentId).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(err => {
        console.error(err);
        this.router.navigate(['ROUTE.Unauthorized']);
        return of(err);
      })
    );
  }
}

@Injectable()
export class StandingsInfoResolver implements Resolve<Observable<any>> {
  players: IPlayer[];
  rounds: IRound[];

  constructor(
    private router: Router,
    private tkcApi: TkcApiService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    return this.tkcApi.getTkcStandings().pipe(
      map((data: any) => {
        const tkcCupStandings = [];
        this.players = data.players;
        this.rounds = data.rounds;
        this.players.forEach((player) => {
          let totalPoints = 0;
          this.rounds.forEach((round) => {
            if (round.playerId === player._id) {
              totalPoints += parseInt(round.tkcPoints, 0);
            }
          });
          const standing = new PlayerStanding(player.firstName + ' ' + player.lastName, totalPoints);
          tkcCupStandings.push(standing);
        });
        return tkcCupStandings;
      }),
      catchError(err => {
        console.error(err);
        this.router.navigate(['ROUTE.Unauthorized']);
        return of(err);
      })
    );
  }
}
