import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICourse, IPlayer, IRound, ITournament, ScoreCard } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-admin-scorecard-create',
  templateUrl: './admin-scorecard-create.component.html',
  styleUrls: ['./admin-scorecard-create.component.css']
})
export class AdminScorecardCreateComponent implements OnInit {
  round: IRound;
  tournaments: ITournament[];
  course: ICourse;
  players: IPlayer[];
  player: IPlayer;
  roundForm: FormGroup;
  showScorecard = false;
  scoreCard: ScoreCard;

  get frontNine(): FormArray {
    return this.roundForm.get('round.frontNine') as FormArray;
  }
  get backNine(): FormArray {
    return this.roundForm.get('round.backNine') as FormArray;
  }

  get frontNineTotal(): number {
    let frontNineTotal = 0;
    this.frontNine.value.forEach(hole => {
      if (hole.holeScore != null) {
        frontNineTotal += parseInt(hole.holeScore, 0);
      }
    });
    return frontNineTotal;
  }

  get backNineTotal(): number {
    let backNineTotal = 0;
    this.backNine.value.forEach(hole => {
      if (hole.holeScore != null) {
        backNineTotal += parseInt(hole.holeScore, 0);
      }
    });
    return backNineTotal;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private dataSvc: TkcDataService
  ) { }

  ngOnInit(): void {
    const roundsData = this.route.snapshot.data.scorecardData;
    this.players = roundsData.players;
    this.tournaments = roundsData.tournaments;
    this.round = this.dataSvc.getBlankRound();

    this.tournaments.forEach((tourney) => {
      tourney.course = roundsData.courses.filter((course) => {
        if (tourney.courseId === course._id) {
          return course;
        }
      });
    });

    this.roundForm = this.fb.group({
      playerId: [null, Validators.required],
      tournamentId: [null, Validators.required],
      playerHandicap: [null],
      tkcPoints: [null, Validators.required],
      place:  [null, Validators.required],
      round: this.fb.group({
        frontNine: this.fb.array([]),
        backNine: this.fb.array([])
      })
    });

    this.roundForm.get('playerId').valueChanges.subscribe((playerId) => {
      if (playerId != null) {
        this.players.forEach((player) => {
          if (player._id === playerId) {
            this.player = player;
            this.roundForm.get('playerHandicap').setValue(this.player.handicap);
          }
        });
      }
    });

    this.roundForm.get('tournamentId').valueChanges.subscribe((tournamentId) => {
      if (tournamentId != null) {
        const tournament = this.tournaments.filter((tourney) => {
          if (tourney._id === tournamentId) {
            return tourney;
          }
        })[0];
        this.scoreCard = new ScoreCard(tournament.course[0].scoreCard);
        this.showScorecard = true;

        if (this.frontNine.value.length >  0) {
          this.clearRoundData();
        } else {
          this.addFrontNine();
          this.addBackNine();
        }
      }
    });
  }

  clearRoundData(): void {
    this.frontNine.controls.forEach((hole) => {
      hole.get('holeScore').setValue(null);
    });
    this.backNine.controls.forEach((hole) => {
      hole.get('holeScore').setValue(null);
    });
  }

  addFrontNine(): void {
    this.round.frontNine.forEach((hole) => {
      this.frontNine.push(this.fb.group({
        holeNumber: this.fb.control(hole.holeNumber),
        holeScore: this.fb.control(hole.holeScore, Validators.required)
      }));
    });
  }

  addBackNine(): void {
    this.round.backNine.forEach((hole) => {
      this.backNine.push(this.fb.group({
        holeNumber: this.fb.control(hole.holeNumber),
        holeScore: this.fb.control(hole.holeScore, Validators.required)
      }));
    });
  }

  createRound(): void {
    if (this.roundForm.valid) {
      this.round.playerId = this.roundForm.value.playerId;
      this.round.tournamentId = this.roundForm.value.tournamentId;
      this.round.frontNine = this.roundForm.value.round.frontNine;
      this.round.backNine = this.roundForm.value.round.backNine;
      this.round.playerHandicap = this.roundForm.value.playerHandicap;
      this.round.tkcPoints = this.roundForm.value.tkcPoints;
      this.round.place = this.roundForm.value.place;

      this.dataSvc.createRound(this.round).then((data) => {
        this.router.navigate(['/admin/admin-scorecards'], { relativeTo: this.route });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

}
