import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor() { }

  @ViewChild('pic1') pic1 : ElementRef;
  @ViewChild('pic2') pic2 : ElementRef;
  @ViewChild('pic3') pic3 : ElementRef;
  @ViewChild('pic4') pic4 : ElementRef;
  @ViewChild('pic5') pic5 : ElementRef;

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    let pos1 = (window.pageYOffset / 5) * -1;
    let pos2 = (window.pageYOffset / 4.5) * -1;

    this.pic1.nativeElement.style.setProperty('transform',`translate(0, ${pos1}px)`);
    this.pic2.nativeElement.style.setProperty('transform',`translate(0, ${pos2}px)`);
    this.pic3.nativeElement.style.setProperty('transform',`translate(0, ${pos1}px)`);
    this.pic4.nativeElement.style.setProperty('transform',`translate(0, ${pos2}px)`);
    this.pic5.nativeElement.style.setProperty('transform',`translate(0, ${pos1}px)`);
}

  ngOnInit(): void {
  }

}
