export interface IPlayer {
  _id: any;
  firstName: string;
  lastName: string;
  fullName: string;
  ghin: string;
  handicap: number;
  email: string;
  isAdmin: boolean;
  isActive: boolean;
}
export interface IHole {
  holeDistance?: number;
  holeScore?: number;
  holePar?: number;
  holeNumber: number;
}
export interface IScoreCard {
  frontNine: IHole[];
  outPar: number;
  outDistance: number;
  backNine: IHole[];
  inPar: number;
  inDistance: number;
  totalPar: number;
  totalDistance: number;
}

export class ScoreCard {

  constructor(scoreCard: IScoreCard) {
    this.frontNine = scoreCard.frontNine;
    this.backNine = scoreCard.backNine;
  }

  frontNine: IHole[];
  backNine: IHole[];

  get GetParOut(): number {
    let parOut = 0;
    if (this.frontNine.some) {
      this.frontNine.forEach((hole) => {
        parOut += hole.holePar;
      });
    }
    return parOut;
  }

  get GetParIn(): number {
    let parIn = 0;
    if (this.frontNine.some) {
      this.backNine.forEach((hole) => {
        parIn += hole.holePar;
      });
    }
    return parIn;
  }

  get GetParTotal(): number {
    let parTotal = 0;
    if (this.frontNine.some) {
      this.frontNine.forEach((hole) => {
        parTotal += hole.holePar;
      });
    }

    if (this.frontNine.some) {
      this.backNine.forEach((hole) => {
        parTotal += hole.holePar;
      });
    }
    return parTotal;
  }

  get GetDistanceOut(): number {
    let distanceOut = 0;
    if (this.frontNine.some) {
      this.frontNine.forEach((hole) => {
        distanceOut += hole.holeDistance;
      });
    }
    return distanceOut;
  }

  get GetDistanceIn(): number {
    let distanceIn = 0;
    if (this.frontNine.some) {
      this.backNine.forEach((hole) => {
        distanceIn += hole.holeDistance;
      });
    }
    return distanceIn;
  }

  get GetDistanceTotal(): number {
    let distanceTotal = 0;
    if (this.frontNine.some) {
      this.frontNine.forEach((hole) => {
        distanceTotal += hole.holeDistance;
      });
    }

    if (this.frontNine.some) {
      this.backNine.forEach((hole) => {
        distanceTotal += hole.holeDistance;
      });
    }
    return distanceTotal;
  }
}

export class LoginUser {
  username: string;
  password: string;
}

export interface ICourse {
  courseId: number;
  courseName: string;
  courseCity: string;
  coursePar: number;
  website?: string;
  scoreCard?: IScoreCard;
}
export interface IInvitation {
  invitationId: number;
  tournamentId: number;
  playerId: number;
  player?: IPlayer;
  status: boolean;
}
export interface ITournament {
  _id?: any;
  tournamentId?: number;
  courseId: number;
  course?: ICourse;
  tournamentInvites?: IInvitation[];
  tournamentDate: string;
  isMajor?: boolean;
  season?: number;
  tournamentName?: string;
}

export interface ITournamentView {
  tournaments: ITournament[];
  courses: ICourse[];
}

export class RoundViewList {
  id: any;
  playerName: string;
  roundPar: number;
  tournamentDate: Date;
  courseName: string;
}

export interface IRound {
  playerId: string;
  tournamentId: string;
  frontNine: IHole[];
  backNine: IHole[];
  tkcPoints: string;
  playerHandicap: number;
  roundOut: number;
  roundIn: number;
  roundTotal: number;
  totalScoreAdjusted: number;
  place: number;
}

export class Round {

  constructor(round: Round, coursePar: number) {
    this.playerId = round.playerId;
    this.playerFirstName = round.playerFirstName;
    this.playerLastName = round.playerLastName;
    this.tournamentId = round.tournamentId;
    this.frontNine = round.frontNine;
    this.backNine = round.backNine;
    this.playerHandicap = round.playerHandicap;
    this.tkcPoints = round.tkcPoints;
    this.place = round.place;
    this.coursePar = coursePar;
  }

  playerId: string;
  playerFirstName?: string;
  playerLastName?: string;
  playerHandicap?: number;
  tournamentId: string;
  frontNine: IHole[];
  backNine: IHole[];
  tkcPoints?: number;
  coursePar?: number;
  place?: number;

  get GetRoundOut(): number {
    let roundOut = 0;
    if (this.frontNine.some) {
      this.frontNine.forEach((hole) => {
        roundOut += hole.holeScore;
      });
    }
    return roundOut;
  }

  get GetRoundIn(): number {
    let roundIn = 0;
    if (this.frontNine.some) {
      this.backNine.forEach((hole) => {
        roundIn += hole.holeScore;
      });
    }
    return roundIn;
  }

  get GetRoundTotal(): number {
    let rountTotal = 0;
    if (this.frontNine.some) {
      this.frontNine.forEach((hole) => {
        rountTotal += hole.holeScore;
      });
    }

    if (this.frontNine.some) {
      this.backNine.forEach((hole) => {
        rountTotal += hole.holeScore;
      });
    }
    return rountTotal;
  }

  get GetNetScore(): number {
    if (this.playerHandicap != null) {
      return (this.GetRoundTotal - this.coursePar) - this.playerHandicap;
    } else {
      return this.GetRoundTotal - this.coursePar;
    }
  }
}


export class PlayerStanding {
  constructor(name: string, points: number) {
    this.name = name;
    this.points = points;
  }
  name: string;
  points: number;
}
