<form [formGroup]="courseForm" class="edit-large-form">
  <div class="row">
    <div class="col-md">
      <div class="form-group">
        <label for="courseName">Course Name</label>
        <input
          type="text"
          class="form-control form-control-sm"
          id="courseName"
          formControlName="courseName"
        />
      </div>
    </div>
    <div class="col-sm">
      <div class="form-group">
        <label for="courseCity">City</label>
        <input
          type="text"
          class="form-control form-control-sm"
          id="courseCity"
          formControlName="courseCity"
        />
      </div>
    </div>
  </div>
<div class="row">
  <div class="col-3">
    <div class="form-group">
      <label for="coursePar">Course Par</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="coursePar"
        formControlName="coursePar"
      />
    </div>
  </div>
  <div class="col">
    <div class="form-group">
      <label for="website">Website</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="website"
        formControlName="website"
      />
    </div>
  </div>
</div>

<div class="row" formGroupName="scoreCard">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-sm table-bordered tksScoreCard">
        <thead class="thead-dark">
          <tr>
            <th>Hole</th>
            <ng-container *ngFor='let cardInfo of course.scoreCard.frontNine'>
              <th class="d-none d-sm-table-cell">{{cardInfo.holeNumber}}</th>
            </ng-container>
            <th>OUT</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Distance</td>
            <ng-container *ngFor='let hole of frontNine.controls; let i = index'  formArrayName="frontNine">
              <ng-container [formGroupName]="i">
                <td class="d-none d-sm-table-cell">
                  <input type="text" class="form-control form-control-sm" attr.id="frontNine-{{i}}" formControlName="holeDistance" />
                </td>
              </ng-container>
            </ng-container>
            <td>{{course.scoreCard.outDistance}}</td>
          </tr>
          <tr>
            <td>Par</td>
            <ng-container *ngFor='let hole of frontNine.controls; let i = index'  formArrayName="frontNine">
              <ng-container>
                <td class="d-none d-sm-table-cell" [formGroupName]="i">
                  <input type="text" class="form-control form-control-sm" attr.id="frontNine-{{i}}" formControlName="holePar" />
                </td>
              </ng-container>
            </ng-container>
            <td>{{course.scoreCard.outPar}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="row" formGroupName="scoreCard">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-sm table-bordered tksScoreCard">
        <thead class="thead-dark">
          <tr>
            <th>Hole</th>
            <ng-container *ngFor='let cardInfo of course.scoreCard.backNine'>
              <th class="d-none d-sm-table-cell">{{cardInfo.holeNumber}}</th>
            </ng-container>
            <th>OUT</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Distance</td>
            <ng-container *ngFor='let hole of backNine.controls; let i = index'  formArrayName="backNine">
              <ng-container [formGroupName]="i">
                <td class="d-none d-sm-table-cell">
                  <input type="text" class="form-control form-control-sm" attr.id="backNine-{{i}}" formControlName="holeDistance" />
                </td>
              </ng-container>
            </ng-container>
            <td>{{course.scoreCard.outDistance}}</td>
          </tr>
          <tr>
            <td>Par</td>
            <ng-container *ngFor='let hole of backNine.controls; let i = index'  formArrayName="backNine">
              <ng-container>
                <td class="d-none d-sm-table-cell" [formGroupName]="i">
                  <input type="text" class="form-control form-control-sm" attr.id="backNine-{{i}}" formControlName="holePar" />
                </td>
              </ng-container>
            </ng-container>
            <td>{{course.scoreCard.outPar}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>








  <button type="submit" (click)="updateCourse()" class="btn btn-primary" [ngClass]="{'disabled': courseForm.invalid}">Update</button>
</form>
