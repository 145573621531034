import { Pipe, PipeTransform } from '@angular/core';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({
  name: 'scoreSort'
})
export class ScoreSort implements PipeTransform {
  transform(array: any, field: string, order: string): any[] {
    console.log(order);
    if (!Array.isArray(array)) {
      return;
    }

    if (order === 'desc') {
      array.sort((a: any, b: any) => ((b[field] - a[field])));
    } else {
      array.sort((a: any, b: any) => ((a[field] > b[field]) ? 1 : -1));
    }

    return array;
  }
}
