<div class="row" id="admin-tournaments-header">
  <div class="row col-9">
    <div class="col-6">
      <h3>Tournaments</h3>
    </div>
    <div class="col-6 text-right">
      <button  class="btn btn-info"  id="scores" (click)="createNewTournament()">Create Tournament</button>
    </div>
  </div>
</div>
<div class="row"  *ngIf='hasTournaments'>
  <div class="col-9">
    <table class="table table-bordered table-striped table-hover table-sm" id="grid-admin">
      <thead>
        <tr>
          <th>Tournament Course</th>
          <th>Tournament Name</th>
          <th>Tournament Date</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let tournament of tournaments'>
          <td class="player-nl">{{tournament.course[0].courseName}}</td>
          <td>{{tournament.tournamentName}}</td>
          <td>{{tournament.tournamentDate | date: 'shortDate'}}</td>
          <td align="center"><a class="alter-link" [routerLink]="['../admin-tournament-edit', tournament._id]">Edit</a></td>
          <td align="center"><a class="alter-link" href="#" (click)="deleteTournament($event, tournament._id, content)">Delete</a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-3">
  </div>
</div>
<div class="row admin-spinner" *ngIf='loading'>
  <div class="spinner-border loader" role="status">
    <span class="sr-only loader">Loading...</span>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span>Are you sure you want to delete this tournament</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('delte')">Delete</button>
  </div>
</ng-template>
