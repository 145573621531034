import { Component, OnInit } from '@angular/core';
import { IPlayer } from '../../models/viewModels';
import { TkcDataService } from '../../services/tkc-data.service';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.css']
})
export class PlayersComponent implements OnInit {
  players: IPlayer[];

  hasPlayers = false;

  constructor(
    private dataSvc: TkcDataService
  ) { }

  ngOnInit(): void {
    this.dataSvc.getActivePlayers().then((data) => {
      console.log(data);
      this.players = data;
      this.hasPlayers = true;
    }).catch((err) => {
      console.log(err);
    });
  }

}
