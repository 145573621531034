<ng-container *ngIf="!isMobile">
  <div class="row admin-container" id="admin-container">
    <div class="col-3">
      <ul class="admin-link-list">
        <li><a routerLink="admin-member" [routerLinkActive]="['active']">Members</a></li>
        <li><a routerLink="admin-courses" [routerLinkActive]="['active']">Courses</a></li>
        <li><a routerLink="admin-tournaments" [routerLinkActive]="['active']">Tournaments</a></li>
        <li><a routerLink="admin-scorecards" [routerLinkActive]="['active']">Rounds</a></li>
      </ul>
    </div>
    <div class="col-9">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isMobile">
  <div class="row">
    <div class="col-12">
      <ul id="mobile-links">
        <li class="mobile-li"><a routerLink="admin-member" [routerLinkActive]="['active']">Members</a></li>
        <li class="mobile-li"><a routerLink="admin-courses" [routerLinkActive]="['active']">Courses</a></li>
        <li class="mobile-li"><a routerLink="admin-tournaments" [routerLinkActive]="['active']">Tournaments</a></li>
        <li class="mobile-li"><a routerLink="admin-scorecards" [routerLinkActive]="['active']">Rounds</a></li>
      </ul>
    </div>
    </div>
    <div class="row">
    <div class="col-12">
      <router-outlet></router-outlet>
    </div>
  </div>

</ng-container>
