
<section id="section_hero" class="section_hero player-detail-hero">
  <div class="heading-wrapper">
    <h3 class="heading-6">PLAYER DETAILS</h3>    
  </div>
</section>

<div *ngIf="hasPlayer" id="player-wrapper">
  <form [formGroup]="playerForm">
    <div class="form-group">
      <label for="firstName">First Name</label>
      <input class="form-control" formControlName="firstName" id="firstName" />
    </div>
    <div class="form-group">
      <label for="lastName">LastName</label>
      <input class="form-control" formControlName="lastName"  id="lastName" />
    </div>
    <div class="form-group">
      <label for="email">Email</label>
      <input class="form-control" formControlName="email" id="email" />
    </div>
    <div class="form-group">
      <label for="handicap">Handicap</label>
      <input class="form-control" formControlName="handicap"  id="handicap" />
    </div>
  </form>
</div>
