<div class="row" id="admin-courses-header">
  <div class="col-9 row">
    <div class="col-6">
      <h3>Courses</h3>
    </div>
    <div class="col-6 text-right">
      <button  class="btn btn-info"  id="scores" (click)="addCourse()">Add Course</button>
    </div>
  </div>
</div>
<div class="row"  *ngIf='hasCourses'>
  <div class="col-9">
    <table class="table table-bordered table-striped table-hover table-sm" id="grid-admin">
      <thead>
        <tr>
          <th>Name</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let course of courses'>
          <td class="player-nl">{{course.courseName}}</td>
          <td align="center"><a class="alter-link" [routerLink]="['../admin-course-edit', course._id]">Edit</a></td>
          <td align="center"><a class="alter-link" href="#" (click)="deleteCourse($event, course._id, content)">Delete</a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-3">
  </div>
</div>
<div class="row admin-spinner"   *ngIf='!hasCourses'>
  <div class="spinner-border loader" role="status">
    <span class="sr-only loader">Loading...</span>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span>Are you sure you want to delete this course</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('delte')">Delete</button>
  </div>
</ng-template>
