import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IPlayer } from '../models/viewModels';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userSubject$ = new BehaviorSubject<IPlayer>(null);
  userActions$ = this.userSubject$.asObservable();

  constructor(private http: HttpClient) { }

  public isAuthenticated(): boolean {
    const userData = localStorage.getItem('userInfo');
    if (userData && JSON.parse(userData)){
      return true;
    }
    return false;
  }

  public getUserInfo(): any {
    const userData = localStorage.getItem('userInfo');
    if (userData && JSON.parse(userData)){
      return JSON.parse(userData).user;
    }
  }

  public setUserInfo(user): void {
    localStorage.setItem('userInfo', JSON.stringify(user));
  }

  public validate(email, password): any {
    return this.http.post('/api/authenticate', { username : email, password }).toPromise();
  }

  public setLoggedInPlayerInMenu(player: IPlayer): void {
    this.userSubject$.next(player);
  }

  public signOut(): void {
    localStorage.removeItem('userInfo');
    localStorage.clear();
  }
}
