import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ICourse, ITournament } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-admin-tournament-create',
  templateUrl: './admin-tournament-create.component.html',
  styleUrls: ['./admin-tournament-create.component.css']
})
export class AdminTournamentCreateComponent implements OnInit {
  tournament: ITournament;
  courses: ICourse[];
  touranmentForm: FormGroup;
  date: {year: number, month: number};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private dataSvc: TkcDataService,
    private calendar: NgbCalendar
  ) { }

  ngOnInit(): void {
    this.courses = this.route.snapshot.data.courses;

    this.touranmentForm = this.fb.group({
      courseId: [null, Validators.required],
      tournamentDate: [null, Validators.required],
      isMajor: null,
      season: [null, Validators.required],
      tournamentName: [null, Validators.required]
    });
  }

  createTournament(): void {
    if (this.touranmentForm.valid) {
      const tournament = { ...this.tournament, ...this.touranmentForm.value };
      tournament.tournamentDate = new Date(
        tournament.tournamentDate.year,
        tournament.tournamentDate.month - 1,
        tournament.tournamentDate.day
      );

      this.dataSvc.createTournament(tournament).then((data) => {
        this.router.navigate(['/admin/admin-tournaments'], { relativeTo: this.route });
      }).catch((err) => {
        console.log(err);
      });
    }
  }
}
