<form [formGroup]="playerForm" class="edit-admin">
  <div class="form-group">
    <label for="firstName">First Name</label>
    <input
      type="text"
      class="form-control"
      id="firstName"
      formControlName="firstName"
    />
  </div>

  <div class="form-group">
    <label for="lastName">Last Name</label>
    <input
      type="text"
      class="form-control"
      id="lastName"
      formControlName="lastName"
    />
  </div>

  <div class="form-group">
    <label for="email">Email</label>
    <input
      type="email"
      class="form-control"
      id="email"
      formControlName="email"
    />
  </div>

  <div class="form-group">
    <label for="handicap">Handicap</label>
    <input
      type="text"
      class="form-control"
      id="handicap"
      formControlName="handicap"
    />
  </div>

  <div class="form-group">
    <label for="ghin">GHIN#</label>
    <input
      type="text"
      class="form-control"
      id="ghin"
      formControlName="ghin"
    />
  </div>

  <div class="form-group form-check">
    <input
      type="checkbox"
      class="form-check-input"
      id="isAdmin"
      formControlName="isAdmin"
    />
    <label class="form-check-label" for="isAdmin">Is Admin</label>
  </div>

  <div class="form-group form-check">
    <input
      type="checkbox"
      class="form-check-input"
      id="isActive"
      formControlName="isActive"
    />
    <label class="form-check-label" for="isActive">Active</label>
  </div>

  <button type="submit" (click)="createPlayer()" class="btn btn-primary" [ngClass]="{'disabled': playerForm.invalid}">Create</button>
</form>
