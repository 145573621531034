import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { IRound, ITournament, ICourse, IPlayer, ScoreCard, Round } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-admin-scorecard-edit',
  templateUrl: './admin-scorecard-edit.component.html',
  styleUrls: ['./admin-scorecard-edit.component.css']
})
export class AdminScorecardEditComponent implements OnInit {
  round: IRound;
  tournament: ITournament;
  course: ICourse;
  scoreCard: ScoreCard;
  player: IPlayer;
  roundForm: FormGroup;

  get frontNine(): FormArray {
    return this.roundForm.get('frontNine') as FormArray;
  }
  get backNine(): FormArray {
    return this.roundForm.get('backNine') as FormArray;
  }

  get frontNineTotal(): number {
    let frontNineTotal = 0;
    this.frontNine.value.forEach(hole => {
      if (hole.holeScore != null) {
        frontNineTotal += parseInt(hole.holeScore, 0);
      }
    });
    return frontNineTotal;
  }

  get backNineTotal(): number {
    let backNineTotal = 0;
    this.backNine.value.forEach(hole => {
      if (hole.holeScore != null) {
        backNineTotal += parseInt(hole.holeScore, 0);
      }
    });
    return backNineTotal;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private dataSvc: TkcDataService
  ) { }

  ngOnInit(): void {
    const roundData = this.route.snapshot.data.round;
    this.player = roundData.player;
    this.course = roundData.course;
    this.round = roundData.round;
    this.scoreCard = new ScoreCard(this.course.scoreCard);
    this.tournament = roundData.tournament;


    this.roundForm = this.fb.group({
      playerHandicap: this.round.playerHandicap,
      tkcPoints: this.round.tkcPoints,
      place: this.round.place,
      frontNine: this.fb.array([]),
      backNine: this.fb.array([])
    });

    this.addFrontNine();
    this.addBackNine();
  }

  addFrontNine(): void {
    this.round.frontNine.forEach((hole) => {
      this.frontNine.push(this.fb.group({
        holeNumber: this.fb.control(hole.holeNumber),
        holeScore: this.fb.control(hole.holeScore, Validators.required)
      }));
    });
  }

  addBackNine(): void {
    this.round.backNine.forEach((hole) => {
      this.backNine.push(this.fb.group({
        holeNumber: this.fb.control(hole.holeNumber),
        holeScore: this.fb.control(hole.holeScore, Validators.required)
      }));
    });
  }

  updateRound(): void {
    if (this.roundForm.valid) {
      const round = {...this.round, ...this.roundForm.value };

      this.dataSvc.updateRound(round).then((data) => {
        this.router.navigate(['/admin/admin-scorecards'], { relativeTo: this.route });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

}
