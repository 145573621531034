

<form [formGroup]="loginForm" id="email-form" class="signin_form">
<div class="row" id="login-form">
  <div class="col-md-4 col-sm-0"></div>
  <div *ngIf="!showCreatePassword" class="col-md-12 col-sm-12">
      <div class="input-group">
        <input type="email" placeholder="Email"  formControlName='username' class="text-field w-input" maxlength="256" id="username"  aria-label="Email" aria-describedby="emailHelp">
        <div class="input-group-append">
          <button class="signin_form_cta w-button" [ngClass]="{'disabled': loginForm.get('username').value != ''}" type="button" id="emailHelp" (click)="verifyEmail()">
            Next
          </button>
        </div>
      </div>

      <div class="mb-3" *ngIf="showPasswordSection">
        <div class="input-group">
          <input type="password" placeholder="Password"  formControlName='password' class="text-field w-input" maxlength="256" id="password"  aria-label="password" aria-describedby="password">
          <div class="input-group-append">
            <button class="signin_form_cta w-button" [ngClass]="{'disabled': loginForm.get('password').value != ''}" type="button" id="passwordBtn" (click)="submit()">Sign in</button>
          </div>
        </div>
      </div>
      <div *ngIf="invalidLogin" class="mb-3">
        Invalid Login!
      </div>
  </div>
  <div *ngIf="showCreatePassword"  class="col-md-4 col-sm-12">
    <h3>Create Password</h3>
    <div class="form-group">
      <label for="newPassword">Password</label>
      <input
        type="text"
        class="form-control"
        id="newPassword"
        formControlName="newPassword"
      />
    </div>
    <p>
      Passwords must be at least 6 characters and contain at least one letter and one number. Passwords are case sensitive.
    </p>

    <div class="form-group">
      <label for="confirmPassword">Confirm Password</label>
      <input
        type="text"
        class="form-control"
        id="confirmPassword"
        formControlName="confirmPassword"
      />
    </div>
    <button type="submit" (click)="createPassword()" class="btn btn-primary" [ngClass]="{'disabled': loginForm.invalid}">Create</button>
  </div>
  <div class="col-md-4 col-sm-0">

  </div>
</div>
</form>
