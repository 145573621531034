import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TkcDataService } from 'src/app/services/tkc-data.service';
import { ITournament } from '../../models/viewModels'; 
import { SwiperComponent } from "swiper/angular";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
  Swiper
} from "swiper/core";

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);




@Component({
  selector: 'app-tournaments',
  templateUrl: './tournaments.component.html',
  styleUrls: ['./tournaments.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TournamentsComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,
    private dataSvc: TkcDataService
  ) { }

  hasTournaments = true;

  tournaments: ITournament[];

  

  ngOnInit(): void {
    Swiper
    this.dataSvc.getTournaments().then((data) => {
      this.tournaments = data.tournaments;

      this.tournaments.forEach((tourney) => {
        tourney.course = data.courses.filter((course) => {
          if (course._id === tourney.courseId) { return course; }
        });
        
      });

      console.log(this.tournaments);
    }).catch((err) => {
      console.log(err);
    });
  }

  hasScores(tournament: any): boolean {
    // console.log(tournament);
    const tournamentDate = tournament.tournamentDate;
    const tdMilli = Date.parse(tournamentDate)
    const td = new Date(tdMilli);
    const today = new Date();
    today.setHours(0,0,0,0);
    if(td <= today) {
      return false;
    } else {
        return true;
      }
  }

  ngAfterViewInit(): void {
  }

  getDateTour(date) {
    return date.split(',')[0]
  }

  getMultiDateTour(date) {
    let iterations = 8;
    let res = '';
    for(var i=0; i<iterations;i++) {
      res +="  *  " + date.split(',')[0].toUpperCase() + "  ";
    }
    return res;
  }


  // tslint:disable-next-line:typedef
  gotToScores(tournamentId: number, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.router.navigate(['/tournamentDetails', tournamentId]);
  }
}
