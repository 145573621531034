import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IRound, RoundViewList } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-admin-scorecards',
  templateUrl: './admin-scorecards.component.html',
  styleUrls: ['./admin-scorecards.component.css']
})
export class AdminScorecardsComponent implements OnInit {
  hasRounds =  false;
  loading = true;
  rounds = [];

  constructor(
    private dataSvc: TkcDataService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.dataSvc.getRounds().then((data) => {
      if (data) {
        data.rounds.forEach(r => {
          const round = new RoundViewList();
          round.id = r._id;
          const player = data.players.filter((p) => {
            if (r.playerId === p._id) {
              return p;
            }
          })[0];
          round.playerName = player.firstName + ' ' + player.lastName;

          const tournament  = data.tournaments.filter((t) => {
            if (r.tournamentId === t._id) {
              return t;
            }
          })[0];

          round.tournamentDate = tournament.tournamentDate;

          const course = data.courses.filter((c) => {
            if (tournament.courseId === c._id) {
              return c;
            }
          })[0];

          round.courseName = course.courseName;
          let totalScore = 0;
          r.frontNine.forEach(fn => {
            totalScore += fn.holeScore;
          });

          r.backNine.forEach(fn => {
            totalScore += fn.holeScore;
          });

          round.roundPar = totalScore;
          this.rounds.push(round);
        });
        this.hasRounds = true;
      }
      this.loading = false;
    }).catch((err) => {
      console.log(err);
    });
  }

  addRound(): void {
    this.router.navigate(['../admin-scorecard-create'], { relativeTo: this.route });
  }

  deleteRound(event: any, roundId: string, content: any): void {
    if (event) {
      event.preventDefault();
    }
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.dataSvc.deleteRound(roundId).then((data) => {
        this.rounds = this.rounds.filter((round) => { if (round.id !== roundId) { return round; } });
        this.hasRounds = true;
      }).catch((err) => {
        console.log(err);
      });
    }, (reason) => {
    });
  }

}
