import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ITournament } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-admin-tournaments',
  templateUrl: './admin-tournaments.component.html',
  styleUrls: ['./admin-tournaments.component.css']
})
export class AdminTournamentsComponent implements OnInit {
  hasTournaments =  false;
  loading = true;
  tournaments: ITournament[];

  constructor(
    private dataSvc: TkcDataService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.dataSvc.getTournaments().then((data) => {
      this.tournaments = data.tournaments;
      this.tournaments.forEach((tourney) => {
        tourney.course = data.courses.filter((course) => {
          if (course._id === tourney.courseId) { return course; }
        });
      });
      this.loading = false;
      this.hasTournaments = this.tournaments.length > 0;
    }).catch((err) => {
      console.log(err);
    });
  }

  createNewTournament(): void {
    this.router.navigate(['../admin-tournament-create'], { relativeTo: this.route });
  }

  deleteTournament(event: any, tournamentId: string, content: any): void {
    if (event) {
      event.preventDefault();
    }
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.loading = true;
      this.dataSvc.deleteTournament(tournamentId).then((data) => {
        this.tournaments = data.tournaments;
        this.tournaments.forEach((tourney) => {
          tourney.course = data.courses.filter((course) => {
            if (course._id === tourney.courseId) { return course; }
          });
        });
        this.loading = false;
        this.hasTournaments = this.tournaments.length > 0;
      }).catch((err) => {
        console.log(err);
      });
    }, (reason) => {
      console.log(reason);
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
