<form [formGroup]="roundForm" class="edit-large-form">
  <div class="row">
    <div class="col-6">
      <label>Player</label>: &nbsp;
      <span>{{player.firstName}} {{player.lastName}}</span>
    </div>

    <div class="col-6">
      <label>Tournament</label>: &nbsp;
      <span>{{course.courseName}} - {{tournament.tournamentDate | date: 'shortDate'}}</span>
    </div>
  </div>
  <!-- FRONT NINE -->
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-sm table-bordered tksScoreCard">
          <thead class="thead-dark">
            <tr>
              <th>Hole</th>
              <ng-container *ngFor='let cardInfo of scoreCard.frontNine'>
                <th>{{cardInfo.holeNumber}}</th>
              </ng-container>
              <th>OUT</th>
            </tr>
          </thead>
          <thead class="table-secondary">
            <tr>
              <th>Distance</th>
              <ng-container *ngFor='let cardInfo of scoreCard.frontNine'>
                <th>{{cardInfo.holeDistance}}</th>
              </ng-container>
              <th>{{scoreCard.GetDistanceOut}}</th>
            </tr>
          </thead>
          <thead class="table-info">
            <tr>
              <th>Par</th>
              <ng-container *ngFor='let cardInfo of scoreCard.frontNine'>
                <th>{{cardInfo.holePar}}</th>
              </ng-container>
              <th>{{scoreCard.GetParOut}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Score</td>
              <ng-container *ngFor='let hole of frontNine.controls; let i = index'  formArrayName="frontNine">
                <ng-container [formGroupName]="i">
                  <td>
                    <input type="text" class="form-control form-control-sm" attr.id="frontNine-{{i}}" formControlName="holeScore" />
                  </td>
                </ng-container>
              </ng-container>
              <td>{{frontNineTotal}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- BACK NINE -->
  <div class="row" >
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-sm table-bordered tksScoreCard">
          <thead class="thead-dark">
            <tr>
              <th>Hole</th>
              <ng-container *ngFor='let cardInfo of scoreCard.backNine'>
                <th>{{cardInfo.holeNumber}}</th>
              </ng-container>
              <th>IN</th>
            </tr>
          </thead>
          <thead class="table-secondary">
            <tr>
              <th>Distance</th>
              <ng-container *ngFor='let cardInfo of scoreCard.backNine'>
                <th>{{cardInfo.holeDistance}}</th>
              </ng-container>
              <th>{{scoreCard.GetDistanceIn}}</th>
            </tr>
          </thead>
          <thead class="table-info">
            <tr>
              <th>Par</th>
              <ng-container *ngFor='let cardInfo of scoreCard.backNine'>
                <th>{{cardInfo.holePar}}</th>
              </ng-container>
              <th>{{scoreCard.GetParIn}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Score</td>
              <ng-container *ngFor='let hole of backNine.controls; let i = index'  formArrayName="backNine">
                <ng-container [formGroupName]="i">
                  <td>
                    <input type="text" class="form-control form-control-sm" attr.id="backNine-{{i}}" formControlName="holeScore" />
                  </td>
                </ng-container>
              </ng-container>
              <td>{{backNineTotal}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <label><b>Total</b></label>: &nbsp;
      <span>{{frontNineTotal + backNineTotal}}</span>
    </div>
    <div class="col-3">
      <label><b>Gross Total</b></label>: &nbsp;
      <span>{{(frontNineTotal + backNineTotal) - scoreCard.GetParTotal}}</span>
    </div>
    <div class="col-3">
      <label><b><i class="fas fa-wheelchair"></i></b></label>: &nbsp;
      <span>{{player.handicap}}</span>
    </div>
    <div class="col-3">
      <label><b>Net</b></label>: &nbsp;
      <span>{{(frontNineTotal + backNineTotal) - player.handicap}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <label><b>TKC Points</b></label>: &nbsp;
      <input type="text" class="form-control form-control-sm" formControlName="tkcPoints" />
    </div>
    <div class="col-6">
      <label><b>Place</b></label>: &nbsp;
      <input type="text" class="form-control form-control-sm" formControlName="place" />
    </div>
  </div>

  <button type="submit" (click)="updateRound()" class="btn btn-primary" [ngClass]="{'disabled': roundForm.invalid}">Update</button>
</form>
