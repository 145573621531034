<h1>Courses</h1>
<table class="table table-bordered table-striped table-hover table-sm">
  <thead>
    <tr>
      <th>Course Name</th>
      <th class="d-none d-sm-table-cell">City</th>
      <th>Par</th>
      <th>Website</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor='let course of courses'>
      <td>{{course.courseName}}</td>
      <td class="d-none d-sm-table-cell"> {{course.courseCity}}</td>
      <td> {{course.coursePar}}</td>
      <td><a [href]="course.website" target="_blank"><i class="fas fa-external-link-alt"></i></a></td>
    </tr>
  </tbody>
</table>
