import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICourse, IScoreCard } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-admin-course-edit',
  templateUrl: './admin-course-edit.component.html',
  styleUrls: ['./admin-course-edit.component.css']
})
export class AdminCourseEditComponent implements OnInit {
  course: ICourse;
  courseForm: FormGroup;
  scoreCard: IScoreCard;

  get frontNine(): FormArray {
    return this.courseForm.get('scoreCard.frontNine') as FormArray;
  }
  get backNine(): FormArray {
    return this.courseForm.get('scoreCard.backNine') as FormArray;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private dataSvc: TkcDataService
  ) { }

  ngOnInit(): void {
    this.course = this.route.snapshot.data.course;

    if (this.course.scoreCard == null) {
      this.course.scoreCard = this.dataSvc.getBlankScorecard();
    }

    this.courseForm = this.fb.group({
      courseName: [this.course.courseName, Validators.required],
      courseCity: [this.course.courseCity, Validators.required],
      coursePar: [this.course.coursePar, Validators.required],
      website: this.course.website,
      scoreCard: this.fb.group ({
        frontNine: this.fb.array([]),
        outPar: this.course.scoreCard.outPar,
        outDistance: this.course.scoreCard.outDistance,
        backNine: this.fb.array([]),
        inPar: this.course.scoreCard.inPar,
        inDistance: this.course.scoreCard.inDistance,
        totalPar: this.course.scoreCard.totalPar,
        totalDistance: this.course.scoreCard.totalDistance
      })
    });

    this.addFrontNine();
    this.addBackNine();
  }

  addFrontNine(): void {
    this.course.scoreCard.frontNine.forEach((hole) => {
      this.frontNine.push(this.fb.group({
        holeNumber: this.fb.control(hole.holeNumber),
        holeDistance: this.fb.control(hole.holeDistance, Validators.required),
        holePar: this.fb.control(hole.holePar, Validators.required)
      }));
    });
  }

  addBackNine(): void {
    this.course.scoreCard.backNine.forEach((hole) => {
      this.backNine.push(this.fb.group({
        holeNumber: this.fb.control(hole.holeNumber),
        holeDistance: this.fb.control(hole.holeDistance),
        holePar: this.fb.control(hole.holePar, Validators.required)
      }));
    });
  }

  updateCourse(): void {
    if (this.courseForm.valid) {
      const course = {...this.course, ...this.courseForm.value };

      this.dataSvc.updateCourse(course).then((data) => {
        this.router.navigate(['/admin/admin-courses'], { relativeTo: this.route });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

}
