import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ICourse, ITournament } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-admin-tournament-edit',
  templateUrl: './admin-tournament-edit.component.html',
  styleUrls: ['./admin-tournament-edit.component.css']
})
export class AdminTournamentEditComponent implements OnInit {
  tournament: ITournament;
  courses: ICourse[];
  touranmentForm: FormGroup;
  date: NgbDate;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private dataSvc: TkcDataService,
  ) { }

  ngOnInit(): void {
    const tournamentInfo = this.route.snapshot.data.tournamentInfo;
    this.tournament = tournamentInfo.tournament;
    this.courses = tournamentInfo.courses;
    const courseDate = new Date(this.tournament.tournamentDate);
    this.date = new NgbDate(courseDate.getFullYear(), courseDate.getMonth() + 1, courseDate.getDate());

    this.touranmentForm = this.fb.group({
      courseId: [this.tournament.courseId, Validators.required],
      tournamentDate: [this.date, Validators.required],
      isMajor: this.tournament.isMajor,
      tournamentName: this.tournament.tournamentName
    });
  }

  updateTournament(): void {
    if (this.touranmentForm.valid) {
      const tournament = {...this.tournament, ...this.touranmentForm.value };
      tournament.tournamentDate = new Date(
        tournament.tournamentDate.year,
        tournament.tournamentDate.month - 1,
        tournament.tournamentDate.day
      );

      this.dataSvc.updateTournament(tournament).then((data) => {
        this.router.navigate(['/admin/admin-tournaments'], { relativeTo: this.route });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

}
