import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IPlayer } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-admin-member-create',
  templateUrl: './admin-member-create.component.html',
  styleUrls: ['./admin-member-create.component.css']
})
export class AdminMemberCreateComponent implements OnInit {
  player: IPlayer;
  playerForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private dataSvc: TkcDataService,
  ) { }

  ngOnInit(): void {
    this.playerForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, Validators.required],
      ghin: null,
      handicap: [null],
      isAdmin: false,
      isActive: true
    });
  }

  createPlayer(): void {
    if (this.playerForm.valid) {
      const player = { ...this.player, ...this.playerForm.value };

      this.dataSvc.createPlayer(player).then((data) => {
        this.router.navigate(['/admin/admin-member'], { relativeTo: this.route });
      }).catch((err) => {
        console.log(err);
      });
    }
  }
}
