import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ICourse, IPlayer, ITournament } from 'src/app/models/viewModels';
import { TkcApiService } from 'src/app/services/tkc-api.service';

@Injectable()
export class AdminMemberEditResolver implements Resolve<Observable<IPlayer>> {
  constructor(
    private router: Router,
    private tkcApi: TkcApiService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPlayer> {
        const playerId = route.params.playerId;
        if (playerId == null) {
          return of(null);
        }

        return this.tkcApi.getPlayerById(playerId).pipe(
            map((playerData: IPlayer) => {
                return playerData;
            }),
            catchError(err => {
                console.error(err);
                this.router.navigate(['ROUTE.Unauthorized']);
                return of(err);
            })
        );
  }
}

@Injectable()
export class AdminCourseEditResolver implements Resolve<Observable<ICourse>> {
  constructor(
    private router: Router,
    private tkcApi: TkcApiService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICourse> {
        const courseId = route.params.courseId;
        if (courseId == null) {
          return of(null);
        }

        return this.tkcApi.getCourse(courseId).pipe(
            map((courseData: ICourse) => {
                return courseData;
            }),
            catchError(err => {
                console.error(err);
                this.router.navigate(['ROUTE.Unauthorized']);
                return of(err);
            })
        );
  }
}

@Injectable()
export class AdminTournamentEditResolver implements Resolve<Observable<ITournament>> {
  constructor(
    private router: Router,
    private tkcApi: TkcApiService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITournament> {
        const tournamentId = route.params.tournamentId;
        if (tournamentId == null) {
          return of(null);
        }

        return this.tkcApi.getTournament(tournamentId).pipe(
            map((data: any) => {
                return data;
            }),
            catchError(err => {
                console.error(err);
                this.router.navigate(['ROUTE.Unauthorized']);
                return of(err);
            })
        );
  }
}

@Injectable()
export class AdminTournamentCreateResolver implements Resolve<Observable<ICourse[]>> {
  constructor(
    private router: Router,
    private tkcApi: TkcApiService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICourse[]> {
    return this.tkcApi.getCourses().pipe(
      map((courseData: ICourse[]) => {
        return courseData;
      }),
      catchError(err => {
          console.error(err);
          this.router.navigate(['ROUTE.Unauthorized']);
          return of(err);
      })
    );
  }
}

@Injectable()
export class AdminScorecardEditResolver implements Resolve<Observable<ITournament>> {
  constructor(
    private router: Router,
    private tkcApi: TkcApiService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITournament> {
        const roundId = route.params.roundId;
        if (roundId == null) {
          return of(null);
        }

        return this.tkcApi.getRound(roundId).pipe(
            map((data: any) => {
                return data;
            }),
            catchError(err => {
                console.error(err);
                this.router.navigate(['ROUTE.Unauthorized']);
                return of(err);
            })
        );
  }
}

@Injectable()
export class AdminScorecardCreateResolver implements Resolve<Observable<ICourse[]>> {
  constructor(
    private router: Router,
    private tkcApi: TkcApiService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICourse[]> {
    return this.tkcApi.getCreateRoundData().pipe(
      map((courseData: ICourse[]) => {
        return courseData;
      }),
      catchError(err => {
          console.error(err);
          this.router.navigate(['ROUTE.Unauthorized']);
          return of(err);
      })
    );
  }
}
