import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPlayer, IRound, PlayerStanding } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-standings',
  templateUrl: './standings.component.html',
  styleUrls: ['./standings.component.css']
})
export class StandingsComponent implements OnInit {
  players: IPlayer[];
  rounds: IRound[];
  tkcCupStandings = [];

  constructor(
    private tkcData: TkcDataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.tkcCupStandings = this.route.snapshot.data.standingsData;
  }
}
