<form [formGroup]="touranmentForm" class="edit-large-form">
  <div class="row">
    <div class="form-group col-6">
      <label for="tournamentDate">Course</label>
      <select class="custom-select" formControlName="courseId">
        <option *ngFor="let course of courses" [value]="course._id">{{course.courseName}}</option>
      </select>
    </div>

    <div class="form-group col-6">
      <label for="tournamentDate">Tournament Date</label>
      <div class="input-group">
        <input class="form-control" placeholder="mm/dd/yyy"
               name="dp" formControlName="tournamentDate" ngbDatepicker #d="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-6">
      <label for="tournamentName">Tournament Name</label>
      <input
        type="text"
        class="form-control"
        id="tournamentName"
        formControlName="tournamentName"
      />
    </div>
    <div class="form-group col-3">
      <label for="season">Season</label>
      <input
        type="text"
        class="form-control"
        id="season"
        formControlName="season"
      />
    </div>
  </div>

  <div class="form-group form-check">
    <input
      type="checkbox"
      class="form-check-input"
      id="isMajor"
      formControlName="isMajor"
    />
    <label class="form-check-label" for="isMajor">Is Major</label>
  </div>

  <button type="submit" (click)="createTournament()" class="btn btn-primary" [ngClass]="{'disabled': touranmentForm.invalid}">Create</button>
</form>
