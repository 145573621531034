import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICourse } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-admin-courses',
  templateUrl: './admin-courses.component.html',
  styleUrls: ['./admin-courses.component.css']
})
export class AdminCoursesComponent implements OnInit {
  hasCourses = false;
  courses: ICourse[];

  constructor(
    private dataSvc: TkcDataService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.dataSvc.getCourses().then((data) => {
      console.log(data);
      this.courses = data;
      this.hasCourses = true;
    }).catch((err) => {
      console.log(err);
    });
  }

  addCourse(): void {
    this.router.navigate(['../admin-course-create'], { relativeTo: this.route });
  }

  deleteCourse(event: any, courseId: string, content: any): void {
    if (event) {
      event.preventDefault();
    }
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      console.log(result);
      this.dataSvc.deleteCourse(courseId).then((data) => {
        this.courses = data;
        this.hasCourses = true;
      }).catch((err) => {
        console.log(err);
      });
    }, (reason) => {
      console.log(reason);
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
