<!--<div class="row" *ngIf="!isMobile">
  <div class="col-12">
    <div class="row">
      <div class="col-1"></div>
      <div id="home_logo" class="col-10 text-center">
        <ngb-carousel
          #carousel
          [interval]="10000"
          [pauseOnHover]="pauseOnHover"
          (slide)="onSlide($event)"
        >
          <ng-template ngbSlide *ngFor="let img of images; index as i">
            <img
              [src]="img"
              class="d-block w-100"
              alt="My image {{ i + 1 }} description"
            />
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isMobile">
      <img src="/assets/images/tkc_cabbage_color.png" alt="Club Logo" class="center" />
</div>
-->
<section id="section_hero" class="section_hero" >
  <div class="heading-wrapper">
    <h3>TKC GOLF CLUB</h3>
    <div class="hero_heading_wrapper">
      <h5 class="line-1" data-w-id="772a3a18-837d-8d4b-c32e-4b3190ef670c" style="opacity:0">FRIENDS WHO</h5>
      <h5 data-w-id="3224dd71-231e-ef6f-d594-c569d2bcf35b" style="opacity:0" class="colored-text line-2">LOVE TO PLAY</h5>
      <h5 class="line-3" data-w-id="56be8fec-fdfc-8fc0-fc1e-981b770d86ed" style="opacity:0">GOLF</h5>
    </div>
  </div>
</section> 

<section class="section_sub_hero">
  <img src="../../../assets/images/Mask-group-1.svg" loading="lazy" width="471" style="opacity:0" alt="" data-w-id="1b2f42b6-4fa2-6ea5-b812-619592e20171" class="hero-img-left">
  <img src="../../../assets/images/Mask-group-2.svg" loading="lazy" width="471" style="opacity:0" alt="" data-w-id="f532e3ba-0316-4840-a6a7-15520d9d6aee" class="hero-img-1">
  <img src="../../../assets/images/Mask-group-3.svg" loading="lazy" width="471" style="opacity:0" alt="" data-w-id="b3ff6a61-4025-1c3a-3a5d-64e788b29a77" class="hero-img-right">
</section>

<section id="section_legend" class="section_legend">
  <h3>ABOUT US</h3>
  <h2 class="heading-2"><span class="colored-text">Started in 2020 by friends</span> who love to play golf and wanted to share their appreciation and dedication to the game by organizing tournaments and <span class="colored-text">welcoming anyone with interest</span>.</h2>
  <div class="space90"></div>
  <a href="/about#section_how_play" class="cta_primary w-button">Join Us</a>
</section>

<section id="section_seasons" class="section_seasons">
  <div class="container">
    <h3>Season 4 Tournaments</h3>
  </div>
 
<div data-w-id="d2b72ae2-c7d4-b18d-00b5-683a76778f05" *ngFor='let tournament of tournaments' class="season_item" >
  <div class="season_item-data-wrapper" [routerLink]="['/tournamentDetails', tournament._id]">
    <div class="season_item_date">{{getDateTour(tournament.tournamentDate | date: 'mediumDate')}}</div>
    <div class="season_item_place_wrapper">
      <div class="season_item_place">{{tournament.tournamentName}}</div>
      <div class="season_item_place_at">@ {{tournament.course[0].courseName}}</div>
    </div>
  </div>
  <img [src]="getCourseImageSrc(tournament.course[0].courseId)" loading="lazy" width="394" height="230" alt="Golf Players having fun" [srcset]="getCourseImageScrSet(tournament.course[0].courseId)" sizes="(max-width: 767px) 100vw, 393.9930725097656px" class="image-3">
  <div class="season_item_bg"></div>
</div>


<div class="center_content">
    <div class="space90"></div>
    <a href="/about#section_how_play" class="cta_primary cta_alter w-button">Join Us</a>
  </div>
</section>

<section class="section_standings">
  <div class="container">
    <h3>TKC STANDING</h3>
  </div>
  <div data-w-id="7926a0a1-8bd9-5ee3-4a7f-f45b246baf43" class="standing_item" *ngFor='let player of tkcCupStandings.slice(0, 5);let indexOfelement=index'>
    <div class="standing_item_hash">#{{indexOfelement + 1}}</div> 
    <div class="standing_item_player">{{player.name}}</div>
    <div class="standing_item_pts-wrapper">
      <div class="standing_item_label">Points</div>
      <div class="standing_item_points">{{player.points}}</div>
    </div>
    <div class="standind_item-bg"></div>
  </div>
  <div class="space90"></div>
  <div class="right-align">
    <a href="/standings" class="cta_primary w-button">View Full Ranking</a>
  </div>
</section>


<section class="section_footer">
  <h4 class="footer-heading">Ready to hit<br><span class="colored-text">the Greens?</span></h4>
  <div class="center_content">
    <div class="space180"></div>
    <a href="/about#section_how_play" class="cta_primary white w-button">Join Us</a>
    <div class="space90"></div>
    <div class="container">
      <h3>contact</h3>
    </div>
  </div>
  <div class="big-email">TKCGOLFCLUB@GMAIL.COM</div>
  <div class="footer_bottom">
    <div>TKC golf club -  all rights reserved  2024</div>
  </div>
</section>
