import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/home/home.component';
import { ScheduleComponent } from './modules/schedule/schedule.component';
import { AboutComponent } from './modules/about/about.component';
import { PlayersComponent } from './modules/players/players.component';
import { TournamentsComponent } from './modules/tournaments/tournaments.component';
import { CoursesComponent } from './modules/courses/courses.component';
import { TournamentDetailComponent } from './modules/tournaments/tournament-detail/tournament-detail.component';
import { PlayerSort } from './pipes/player-sort.pipe';
import { StandingsComponent } from './modules/standings/standings.component';
import { ScoreSort } from './pipes/score-sort.pipe';
import { LoginComponent } from './modules/login/login.component';
import { AuthInterceptor } from './interceptors/http-auth.interceptors';
import { ErrorInterceptor } from './interceptors/http-error.interceptors';
import { MenuComponent } from './modules/menu/menu.component';
import { PlayerDetailComponent } from './modules/players/player-detail/player-detail.component';
import { StandingsInfoResolver, TournamentInfoResolver } from './app-routing.service';
import { LazyLoadScriptService } from './services/lazy-load-service';
import { AdminModule } from './modules/admin/admin.module';
import { SharedModule } from './modules/shared/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { RulesComponent } from './modules/rules/rules.component';
import { JoinComponent } from './modules/join/join.component';
import { SwiperModule } from "swiper/angular";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ScheduleComponent,
    AboutComponent,
    PlayersComponent,
    TournamentsComponent,
    CoursesComponent,
    TournamentDetailComponent,
    StandingsComponent,
    
    LoginComponent,
    MenuComponent,
    PlayerDetailComponent,
    RulesComponent,
    JoinComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AdminModule,
    AppRoutingModule,
    SwiperModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    TournamentInfoResolver,
    StandingsInfoResolver,
    LazyLoadScriptService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
