import { NgModule } from '@angular/core';
import { AdminComponent } from './admin.component';
import { SharedModule } from '../shared/shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminMemberComponent } from './admin-member/admin-member.component';
import { AdminCoursesComponent } from './admin-courses/admin-courses.component';
import { AdminTournamentsComponent } from './admin-tournaments/admin-tournaments.component';
import { AdminScorecardsComponent } from './admin-scorecards/admin-scorecards.component';
import { AdminMemberEditComponent } from './admin-member/admin-member-edit/admin-member-edit.component';
import { AdminMemberCreateComponent } from './admin-member/admin-member-create/admin-member-create.component';
import {
  AdminCourseEditResolver,
  AdminMemberEditResolver,
  AdminScorecardCreateResolver,
  AdminScorecardEditResolver,
  AdminTournamentCreateResolver,
  AdminTournamentEditResolver
} from './admin-routing.service';
import { AdminCourseCreateComponent } from './admin-courses/admin-course-create/admin-course-create.component';
import { AdminCourseEditComponent } from './admin-courses/admin-course-edit/admin-course-edit.component';
import { AdminTournamentCreateComponent } from './admin-tournaments/admin-tournament-create/admin-tournament-create.component';
import { AdminTournamentEditComponent } from './admin-tournaments/admin-tournament-edit/admin-tournament-edit.component';
import { AdminScorecardCreateComponent } from './admin-scorecards/admin-scorecard-create/admin-scorecard-create.component';
import { AdminScorecardEditComponent } from './admin-scorecards/admin-scorecard-edit/admin-scorecard-edit.component';

@NgModule({
  declarations: [
    AdminComponent,
    AdminMemberComponent,
    AdminCoursesComponent,
    AdminTournamentsComponent,
    AdminScorecardsComponent,
    AdminMemberEditComponent,
    AdminMemberCreateComponent,
    AdminCourseCreateComponent,
    AdminCourseEditComponent,
    AdminTournamentCreateComponent,
    AdminTournamentEditComponent,
    AdminScorecardCreateComponent,
    AdminScorecardEditComponent
  ],
  imports: [
    SharedModule,
    AdminRoutingModule
  ],
  providers: [
    AdminMemberEditResolver,
    AdminCourseEditResolver,
    AdminTournamentCreateResolver,
    AdminTournamentEditResolver,
    AdminScorecardEditResolver,
    AdminScorecardCreateResolver
  ]
})
export class AdminModule { }
