import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IPlayer } from './models/viewModels';
import { AuthService } from './services/auth.service';
import {LazyLoadScriptService } from './services/lazy-load-service';

@Component({
  selector: 'app-kc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('navbarSupportedContent') navBar: ElementRef;
  isAuthenticated = false;
  player: IPlayer;

  constructor(public authService: AuthService, private lazyLoadService: LazyLoadScriptService){}

  title = 'TKC Golf Club';

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.isAuthenticated = true;
      this.player = this.authService.getUserInfo();
    }

    /*setTimeout(()=>{
      
      
      this.lazyLoadService.loadScript('../assets/js/webflow.min.js').subscribe(_ => {
        console.log('webflow loaded');    
      });

      
    },2000)*/
  }

  setActiveTab(tab: string): void{
    this.navBar.nativeElement.classList.remove('show');
  }
}
