<section id="section_hero" class="section_hero">
  <div class="heading-wrapper">
    <h3 class="heading-6">TKC GOLF CLUB</h3>
    <div class="hero_heading_wrapper">
      <h5 data-w-id="b67266aa-9e16-1a47-0977-38a45a820b74" style="opacity:0">THE CLUB</h5>
    </div>
  </div>
</section>
<section class="section_parallax_device">

    <img   src="../../../assets/images/club_asset_1.png" loading="lazy" width="100" sizes="(max-width: 479px) 55vw, (max-width: 767px) 76vw, 100vw" alt="" srcset="../../../assets/images/club_asset_1-p-500.png 500w, ../../../assets/images/club_asset_1.png 549w" class="parallax_device_img_half">
  <div class="club_text device_heading">FRIENDS <br>WHO LOVE <br>TO PLAY GOLF <br>WITH <br>DEDICATION <br>TO THE SPORT</div>
  <div class="parallax_device_img_w"><img src="../../../assets/images/club_asset_3.png" loading="lazy" width="100" alt="" class="parallax_device_img_half"><img src="../../../assets/images/club_asset_5.svg" loading="lazy" width="100" alt="" class="parallax_device_img_half"></div>
</section>
<section data-w-id="6a9b3b9c-879c-4b33-ab70-1898fe04d6e3" class="section_parallax">
  <div class="parallax_1"><img #pic1 class="parallax_image parallax_img_1" src="../../../assets/images/club_asset_1.png" width="549" height="299" alt="" sizes="(max-width: 767px) 100vw, 548.9931030273438px" data-w-id="3aa77f07-3338-a532-caa0-6b66c9d0d900" loading="lazy" srcset="../../../assets/images/club_asset_1-p-500.png 500w, ../../../assets/images/club_asset_1.png 549w">
    <div class="club_text">FRIENDS</div>
  </div>
  <div class="parallax_2">
    <div class="parallax_text_w">
      <div data-w-id="8123ec50-0a12-a127-09fb-7060fc92e011" class="club_text">who</div>
      <div class="club_text">love to</div>
    </div><img #pic2 src="../../../assets/images/club_asset_2.svg" loading="lazy" data-w-id="a5a1c44a-e5ad-29d4-202b-6b89f8f93555" height="299" alt="" class="parallax_image parallax_img_2">
  </div>
  <div data-w-id="f0b6f07a-517f-8dcf-da2f-24c3d5cea84a" class="parallax_3">
    <div class="club_text">play golf</div><img #pic3 src="../../../assets/images/club_asset_3.png" loading="lazy" data-w-id="e5c6e4e4-3ee5-9440-9e8c-0a0f4471ccc6" height="299" alt="" class="parallax_image parallax_img_3">
  </div>
  <div class="parallax_4"><img #pic4 src="../../../assets/images/club_asset_4.svg" loading="lazy" data-w-id="f3a45f13-30b3-5735-11ea-d524ca66f0f7" height="299" alt="" class="parallax_image parallax_img_4">
    <div class="parallax_text_w">
      <div data-w-id="a7e21bf5-59d3-91be-735f-8d0cd9b4335a" class="club_text">with</div>
      <div class="club_text">dedication</div>
    </div>
  </div>
  <div class="parallax_5">
    <div class="club_text">to the sport</div><img #pic5 src="../../../assets/images/club_asset_5.svg" loading="lazy" alt="" height="299" class="parallax_image parallax_img_5">
  </div>
</section>
<section class="section_how_play" id="section_how_play">
  <div class="club-container">
    <div class="section_heading">How to join?</div>
    <div class="how_to_play_wrapper">
      <div class="how_to_item"><img src="../../../assets/images/scoring_tri_assets_3.svg" loading="lazy" alt="">
        <div>We hold monthly tournaments typically the 2nd or 3rd Saturday of the month.</div>
      </div>
      <div class="how_to_item"><img src="../../../assets/images/scoring_tri_assets_3.svg" loading="lazy" alt="">
        <div>We are a handicap league. Our membership includes an SGCA membership and a GHIN.</div>
      </div>
      <div class="how_to_item"><img src="../../../assets/images/scoring_tri_assets_3.svg" loading="lazy" alt="">
        <div>We will play a 10 match season starting February and concluding in November.</div>
      </div>
      <div class="how_to_item"><img src="../../../assets/images/scoring_tri_assets_3.svg" loading="lazy" alt="">
        <div>Points are accrued each match as we all battle for the TKC Cup, as well as mid majors with cash prizes.</div>
      </div>
    </div>
    <div class="section_heading">Scoring &amp; Competition</div>
    <div class="how_to_play_wrapper">
      <div class="how_to_item"><img src="../../../assets/images/scoring_tri_assets_2.svg" loading="lazy" alt="">
        <div>We hold monthly tournaments typically the 2nd or 3rd Saturday of the month.</div>
      </div>
      <div class="how_to_item"><img src="../../../assets/images/scoring_tri_assets_2.svg" loading="lazy" alt="">
        <div>We are a handicap league. Our membership includes an SGCA membership and a GHIN.</div>
      </div>
      <div class="how_to_item"><img src="../../../assets/images/scoring_tri_assets_2.svg" loading="lazy" alt="">
        <div>We will play a 10 match season starting February and concluding in November.</div>
      </div>
      <div class="how_to_item"><img src="../../../assets/images/scoring_tri_assets_2.svg" loading="lazy" alt="">
        <div>Points are accrued each match as we all battle for the TKC Cup, as well as mid majors with cash prizes.</div>
      </div>
    </div>
  </div>
</section>
<section class="section_membership">
  <div class="membership_heading">
    <div class="membership_title">membership</div>
    <div class="membership_subtitle">Membership is $71.00 and includes SCGA membership and GHIN</div>
  </div>
  <a href="https://membership.scga.org/start/join/?cid=8924" target="_blank" class="membership_cta w-inline-block">
    <div>Click here to Join</div>
  </a>
</section>
<section class="section_footer">
  <h4 class="footer-heading">Ready to hit<br><span class="colored-text">the Greens?</span></h4>
  <div class="center_content">
    <div class="space180"></div>
    <a href="/about#section_how_play" class="cta_primary white w-button">Join Us</a>
    <div class="space90"></div>
    <div class="container">
      <h3>contact</h3>
    </div>
  </div>
  <div class="big-email">TKCGOLFCLUB@GMAIL.COM</div>
  <div class="footer_bottom">
    <div>TKC golf club -  all rights reserved  2024</div>
  </div>
</section>

<!--<div class="row">
  <div class="col-12">
    <h3>About</h3>
  </div>
</div>

 <div class="col-5">
    <img src="/assets/images/tkc_trophy.jpg" class="rounded img-fluid">
  </div> 
<div class="row">
  <div class="col-12">
    <p>
      Started in 2020 by friends who love to play golf and wanted to share
      their appreciation and dedication to the sport by organizing tournaments
      and welcoming anyone with interest.
    </p>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <p class="center-text">
      <span>Contact us at <a href="mailto:tkcgolfclub@gmail.com">tkcgolfclub@gmail.com</a></span>
    </p>
  </div>
</div>-->
