import { LogHelperService } from './log-helper.service';

export class ApiError {
  message: string;

  error: string;

  statusText: string;
}

export class ApiHelperService {
  constructor(protected logger: LogHelperService) { }

  handleError(error: any, message?: string, propogate?: boolean): Promise<any> {
    if (message) {
      this.logger.log(message);
    }

    this.logger.log(error);

    if (propogate) {
      console.error(error);
    }

    if (typeof (error) !== 'string') {
      const apiError = error as ApiError;
      // eslint-disable-next-line no-param-reassign
      error = apiError.statusText;
    }

    return Promise.reject(error || 'Something went wrong!');
  }
}
