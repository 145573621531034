import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ICourse, IPlayer, IRound, IScoreCard, ITournament, LoginUser } from '../models/viewModels';
import { ApiHelperService } from './api-helper.service';
import { LogHelperService } from './log-helper.service';
import { TkcApiService } from './tkc-api.service';

@Injectable({
  providedIn: 'root'
})
export class TkcDataService extends ApiHelperService {

  courses: ICourse;
  tournaments: ITournament;

  constructor(private apiSvc: TkcApiService, public logger: LogHelperService) { super(logger); }

  //#region AUTH SERVICES
  login(user: LoginUser): Promise<any> {
    return this.apiSvc.login(user).pipe(
      catchError((error) => this.handleError(error, 'fail', true)),
      map((data) => data)
    ).toPromise();
  }

  checkEmail(userEmail: string): Promise<any> {
    return this.apiSvc.checkEmail(userEmail).pipe(
      catchError((error) => this.handleError(error, 'fail', true)),
      map((data) => data)
    ).toPromise();
  }

  createPassword(email: string, password: string): Promise<any> {
    return this.apiSvc.createPassword(email, password).pipe(
      catchError((error) => this.handleError(error, 'fail', true)),
      map((data) => data)
    ).toPromise();
  }
  //#endregion

  getBlankScorecard(): IScoreCard {
    return {
      frontNine: [
        { holeNumber: 1, holeDistance: null, holePar: null },
        { holeNumber: 2, holeDistance: null, holePar: null },
        { holeNumber: 3, holeDistance: null, holePar: null },
        { holeNumber: 4, holeDistance: null, holePar: null },
        { holeNumber: 5, holeDistance: null, holePar: null },
        { holeNumber: 6, holeDistance: null, holePar: null },
        { holeNumber: 7, holeDistance: null, holePar: null },
        { holeNumber: 8, holeDistance: null, holePar: null },
        { holeNumber: 9, holeDistance: null, holePar: null }
      ],
      outPar: null,
      outDistance: null,
      backNine: [
        { holeNumber: 10, holeDistance: null, holePar: null },
        { holeNumber: 11, holeDistance: null, holePar: null },
        { holeNumber: 12, holeDistance: null, holePar: null },
        { holeNumber: 13, holeDistance: null, holePar: null },
        { holeNumber: 14, holeDistance: null, holePar: null },
        { holeNumber: 15, holeDistance: null, holePar: null },
        { holeNumber: 16, holeDistance: null, holePar: null },
        { holeNumber: 17, holeDistance: null, holePar: null },
        { holeNumber: 18, holeDistance: null, holePar: null }
      ],
      inPar: null,
      inDistance: null,
      totalPar: null,
      totalDistance: null
    };
  }

  getBlankRound(): IRound {
    return {
      playerId: null,
      tournamentId: null,
      frontNine: [
        { holeNumber: 1, holeDistance: null, holePar: null },
        { holeNumber: 2, holeDistance: null, holePar: null },
        { holeNumber: 3, holeDistance: null, holePar: null },
        { holeNumber: 4, holeDistance: null, holePar: null },
        { holeNumber: 5, holeDistance: null, holePar: null },
        { holeNumber: 6, holeDistance: null, holePar: null },
        { holeNumber: 7, holeDistance: null, holePar: null },
        { holeNumber: 8, holeDistance: null, holePar: null },
        { holeNumber: 9, holeDistance: null, holePar: null }
      ],
      roundOut: null,
      backNine: [
        { holeNumber: 10, holeDistance: null, holePar: null },
        { holeNumber: 11, holeDistance: null, holePar: null },
        { holeNumber: 12, holeDistance: null, holePar: null },
        { holeNumber: 13, holeDistance: null, holePar: null },
        { holeNumber: 14, holeDistance: null, holePar: null },
        { holeNumber: 15, holeDistance: null, holePar: null },
        { holeNumber: 16, holeDistance: null, holePar: null },
        { holeNumber: 17, holeDistance: null, holePar: null },
        { holeNumber: 18, holeDistance: null, holePar: null }
      ],
      roundIn: null,
      roundTotal: null,
      totalScoreAdjusted: null,
      tkcPoints: null,
      playerHandicap: null,
      place: null
    };
  }

  //#region STANDINGS SERVICES
  getTkcStandings(): Promise<any> {
    return this.apiSvc.getTkcStandings().pipe(
      catchError((error) => this.handleError(error, 'GET TKC STANDINGS FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }
  //#endregion

  //#region PLAYER SERVICES
  createPlayer(player: IPlayer): Promise<IPlayer> {
    return this.apiSvc.createPlayer(player).pipe(
      catchError((error) => this.handleError(error, 'CREATE Player FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  getPlayers(): Promise<IPlayer[]> {
    return this.apiSvc.getPlayers().pipe(
      catchError((error) => this.handleError(error, 'GET Players FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  getActivePlayers(): Promise<IPlayer[]> {
    return this.apiSvc.getActivePlayers().pipe(
      catchError((error) => this.handleError(error, 'GET Players FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  getPlayer(username: string): Promise<IPlayer> {
    return this.apiSvc.getPlayer(username).pipe(
      catchError((error) => this.handleError(error, 'GET Player FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  updatePlayer(player: IPlayer): Promise<IPlayer> {
    return this.apiSvc.updatePlayer(player).pipe(
      catchError((error) => this.handleError(error, 'UPDATE Player FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  deletePlayer(playerId: string): Promise<IPlayer[]> {
    return this.apiSvc.deletePlayer(playerId).pipe(
      catchError((error) => this.handleError(error, 'DELETE Player FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }
  //#endregion

  //#region COURSE SERVICES
  createCourse(course: ICourse): Promise<ICourse> {
    return this.apiSvc.createCourse(course).pipe(
      catchError((error) => this.handleError(error, 'CREATE Course FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  getCourses(): Promise<ICourse[]> {
    return this.apiSvc.getCourses().pipe(
      catchError((error) => this.handleError(error, 'Get Courses FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  getCourse(courseId): Promise<ICourse> {
    return this.apiSvc.getCourse(courseId).pipe(
      catchError((error) => this.handleError(error, 'Get Course FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  updateCourse(course: ICourse): Promise<ICourse> {
    return this.apiSvc.updateCourse(course).pipe(
      catchError((error) => this.handleError(error, 'UPDATE Course FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  deleteCourse(courseId: string): Promise<ICourse[]> {
    return this.apiSvc.deleteCourse(courseId).pipe(
      catchError((error) => this.handleError(error, 'DELETE Course FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }
  //#endregion

  //#region TOURNAMENT SERVICES
  createTournament(tournament: ITournament): Promise<ITournament> {
    return this.apiSvc.createTournament(tournament).pipe(
      catchError((error) => this.handleError(error, 'CREATE Tournament FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  getTournaments(): Promise<any> {
    return this.apiSvc.getTournaments().pipe(
      catchError((error) => this.handleError(error, 'Get Tournaments FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  getTournament(tournamentId: string): Promise<any> {
    return this.apiSvc.getTournament(tournamentId).pipe(
      catchError((error) => this.handleError(error, 'Get Tournaments FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  getTournamentDetails(tournamentId: string): Promise<any> {
    return this.apiSvc.getTournamentDetails(tournamentId).pipe(
      catchError((error) => this.handleError(error, 'Get Tournaments FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  updateTournament(tournament: ITournament): Promise<ITournament> {
    return this.apiSvc.updateTournament(tournament).pipe(
      catchError((error) => this.handleError(error, 'UPDATE Tournament FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  deleteTournament(tournamentId: string): Promise<any> {
    return this.apiSvc.deleteTournament(tournamentId).pipe(
      catchError((error) => this.handleError(error, 'DELETE Tournament FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }
  //#endregion

  //#region  ROUNDS SERVICES
  createRound(round: IRound): Promise<IRound> {
    return this.apiSvc.createRound(round).pipe(
      catchError((error) => this.handleError(error, 'CREATE Round FAILED!', true)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    ).toPromise();
  }

  getRounds(): Promise<any> {
    return this.apiSvc.getRounds().pipe(
      catchError((error) => this.handleError(error, 'Get Rounds FAILED!', true)),
      map((data) => {
        return data;
      })
    ).toPromise();
  }

  getCreateRoundData(): Promise<any> {
    return this.apiSvc.getCreateRoundData().pipe(
      catchError((error) => this.handleError(error, 'GET Round FAILED!', true)),
      map((data) => {
        return data;
      })
    ).toPromise();
  }

  getRound(roundId: string): Promise<any> {
    return this.apiSvc.getTournament(roundId).pipe(
      catchError((error) => this.handleError(error, 'GET Round FAILED!', true)),
      map((data) => {
        return data;
      })
    ).toPromise();
  }

  updateRound(round: IRound): Promise<IRound> {
    return this.apiSvc.updateRound(round).pipe(
      catchError((error) => this.handleError(error, 'UPDATE Round FAILED!', true)),
      map((data) => {
        return data;
      })
    ).toPromise();
  }

  deleteRound(roundId: string): Promise<IRound[]> {
    return this.apiSvc.deleteRound(roundId).pipe(
      catchError((error) => this.handleError(error, 'DELETE Round FAILED!', true)),
      map((data) => {
        return data;
      })
    ).toPromise();
  }
  //#endregion
}
