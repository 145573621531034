
<div class="row">
  <div class="col-9">
    <h1>2020-2021 Schedule</h1>
    <table class="table">
      <thead>
        <tr>
          <th>Location</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Brookside</td>
          <td>10/4</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-3">
  </div>
</div>
