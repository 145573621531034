<section id="section_hero" class="section_hero">
  <div class="heading-wrapper">
    <h3 class="heading-6">TKC GOLF CLUB</h3>
    <div class="hero_heading_wrapper">
      <h5 data-w-id="08aafcfb-6c06-36e4-15df-d372f52497b5" style="opacity:0" class="title-rules">RULES &amp;</h5>
      <h5 data-w-id="08aafcfb-6c06-36e4-15df-d372f52497b7" style="opacity:0" class="heading-5 title-scoring">SCORING</h5>
    </div>
  </div>
</section>
<section class="section_rules">
  <div class="rules_display">We will basically follow the official <a href="https://www.usga.org/rules-hub.html" target="_blank">USGA Rules of Golf </a> format besides the following:</div>
  <ul role="list" class="rules_list">
    <li class="list-item">
      <div class="rules_bullets">When you are on double the par stroke count and not close to finishing the hole, pick up and give yourself a score of double par plus one.</div>
    </li>
    <li class="list-item">
      <div class="rules_bullets">This is to avoid slow play and speed up the pace. </div>
    </li>
    <li class="list-item">
      <div class="rules_bullets">We want these courses to invite us with open arms in future events.</div>
    </li>
    <li class="list-item">
      <div class="rules_bullets">No golf course favors leagues that slow play and hold the course back</div>
    </li>
    <li class="list-item">
      <div class="rules_bullets">OB (out of bounds) will be treated as a hazard.</div>
    </li>
    <li class="list-item">
      <div class="rules_bullets">Meaning if your ball is OB or lost, take a one stroke penalty and take a drop to the nearest line of cross, no closer to the hole. This is also to avoid slow play.</div>
    </li>
  </ul>
  <div class="rules_display_2">And please remember to PUTT OUT. No gimmes whether it&#x27;s a foot or an inch.</div>
</section>
<section class="section_scoring">
  <div class="section_heading">Scoring</div>
  <div class="scoring_grid_wrapper">
    <div class="scoring_grid">
      <div class="scoring_header">
        <div class="scoring_grid_title_wrapper"><img src="../../../assets/images/scoring_tri_assets_1.svg" loading="lazy" width="27" height="27" alt="scoring grid head ribbon">
          <div>Regular Events</div>
        </div>
        <div>Points</div>
      </div>
      <div class="scoring_row">
        <div>1st</div>
        <div>100</div>
      </div>
      <div class="scoring_row">
        <div>2nd</div>
        <div>75</div>
      </div>
      <div class="scoring_row">
        <div>3rd</div>
        <div>50</div>
      </div>
      <div class="scoring_row">
        <div>4th</div>
        <div>25</div>
      </div>
      <div class="scoring_row">
        <div>5th</div>
        <div>20</div>
      </div>
      <div class="scoring_row">
        <div>6th - 10th</div>
        <div>15</div>
      </div>
      <div class="scoring_row">
        <div>11th - 15th</div>
        <div>10</div>
      </div>
      <div class="scoring_row">
        <div>16th - 20th</div>
        <div>5</div>
      </div>
      <div class="scoring_row">
        <div>21st - Last Place</div>
        <div>1</div>
      </div>
    </div>
    <div class="scoring_grid scoring_grid_center">
      <div class="scoring_header">
        <div class="scoring_grid_title_wrapper"><img src="../../../assets/images/scoring_tri_assets_1.svg" loading="lazy" width="27" height="27" alt="scoring grid head ribbon">
          <div>Majors</div>
        </div>
        <div>Points</div>
      </div>
      <div class="scoring_row">
        <div>1st</div>
        <div>150</div>
      </div>
      <div class="scoring_row">
        <div>2nd</div>
        <div>112.5</div>
      </div>
      <div class="scoring_row">
        <div>3rd</div>
        <div>75</div>
      </div>
      <div class="scoring_row">
        <div>4th</div>
        <div>37.5</div>
      </div>
      <div class="scoring_row">
        <div>5th</div>
        <div>30</div>
      </div>
      <div class="scoring_row">
        <div>6th - 10th</div>
        <div>22.5</div>
      </div>
      <div class="scoring_row">
        <div>11th - 15th</div>
        <div>15</div>
      </div>
      <div class="scoring_row">
        <div>16th - 20th</div>
        <div>7.5</div>
      </div>
      <div class="scoring_row">
        <div>21st - Last Place</div>
        <div>1.5</div>
      </div>
    </div>
    <div class="scoring_grid">
      <div class="scoring_header">
        <div class="scoring_grid_title_wrapper"><img src="../../../assets/images/scoring_tri_assets_1.svg" loading="lazy" width="27" height="27" alt="scoring grid head ribbon">
          <div>Club Championship</div>
        </div>
        <div>Points</div>
      </div>
      <div class="scoring_row">
        <div>1st</div>
        <div>200</div>
      </div>
      <div class="scoring_row">
        <div>2nd</div>
        <div>150</div>
      </div>
      <div class="scoring_row">
        <div>3rd</div>
        <div>100</div>
      </div>
      <div class="scoring_row">
        <div>4th</div>
        <div>50</div>
      </div>
      <div class="scoring_row">
        <div>5th</div>
        <div>40</div>
      </div>
      <div class="scoring_row">
        <div>6th - 10th</div>
        <div>30</div>
      </div>
      <div class="scoring_row">
        <div>11th - 15th</div>
        <div>20</div>
      </div>
      <div class="scoring_row">
        <div>16th - 20th</div>
        <div>10</div>
      </div>
      <div class="scoring_row">
        <div>21st - Last Place</div>
        <div>2</div>
      </div>
    </div>
  </div>
</section>
<section class="section_extra">
  <div class="extra_wrapper">
    <div class="extra_banner">
      <div class="extra_marquee_wrapper">
        <div data-w-id="08a2ade2-60dc-a768-ab0d-658741c8c2d0"  class="extra_marquee">
          extra points      *      extra points      *      extra points      *      extra points      *      extra points      *      
        </div>
      </div>
    </div>
    <ul role="list" class="rules_list extra_list">
      <li class="list-item">
        <div class="rules_bullets extra_bullers">Every match will have a longest drive and closest to the pin challange. The winner of these challanges will be awarded an additional 20 points for regular events, 30 points for majors and 40 points for club Championship.</div>
      </li>
      <li class="list-item">
        <div class="rules_bullets extra_bullers">There will also be a $25 cash prize for regular events and $50 for the major/club championship. So practice hitting bombs and darts.</div>
      </li>
    </ul>
  </div>
</section>
<section class="section_footer">
  <h4 class="footer-heading">Ready to hit<br><span class="colored-text">the Greens?</span></h4>
  <div class="center_content">
    <div class="space180"></div>
    <a href="/about#section_how_play" class="cta_primary white w-button">Join Us</a>
    <div class="space90"></div>
    <div class="container">
      <h3>contact</h3>
    </div>
  </div>
  <div class="big-email">TKCGOLFCLUB@GMAIL.COM</div>
  <div class="footer_bottom">
    <div>TKC golf club -  all rights reserved  2024</div>
  </div>
</section>


<!--
  
<div class="row">
  <div class="col-12">
    <h1 style="text-align: center;">Rules</h1>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <p>
      We will basically follow the official
      <a href="https://www.usga.org/rules-hub.html" target="_blank"
        >USGA Rules of Golf</a
      >
      format besides the following:
    </p>

    <ul>
      <li>
        When you are on double the par stroke count and not close to finishing
        the hole, pick up and give yourself a score of double par plus one. This
        is to avoid slow play and speed up the pace. We want these courses to
        invite us with open arms in future events. No golf course favors leagues
        that slow play and hold the course back.
      </li>
      <li>
        OB (out of bounds) will be treated as a hazard. Meaning if your ball is
        OB or lost, take a one stroke penalty and take a drop to the nearest
        line of cross, no closer to the hole. This is also to avoid slow play.
      </li>
    </ul>
  </div>
  <div class="col-12">
    <p>
      And please remember to PUTT OUT. No gimmes whether it's a foot or an inch.
    </p>
  </div>
    <div class="col-12">
      <h1 style="text-align: center; padding: 10px;">Scoring</h1>
    </div>
</div>
<div class="row">
  <div class="col-md-4 col-sm-12">
    <div class="table-responsive">
      <table class="table table-sm table-bordered">
        <thead class="table-warning">
          <tr>
            <th>Regular Events</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style="white-space: nowrap;">1st</th>
            <th>100</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">2nd</th>
            <th>75</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">3rd</th>
            <th>50</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">4th</th>
            <th>25</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">5th</th>
            <th>20</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">6th - 10th</th>
            <th>15</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">11th - 15th</th>
            <th>10</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">16th - 20th</th>
            <th>5</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">21st - Last Place</th>
            <th>1</th>
          </tr>
        </tbody>
      </table>
  </div>
  </div>
  <div class="col-md-4 col-sm-12">
    <div class="table-responsive">
      <table class="table table-sm table-bordered">
        <thead class="table-info">
          <tr>
            <th>Majors</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style="white-space: nowrap;">1st</th>
            <th>150</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">2nd</th>
            <th>112.5</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">3rd</th>
            <th>75</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">4th</th>
            <th>37.5</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">5th</th>
            <th>30</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">6th - 10th</th>
            <th>22.5</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">11th - 15th</th>
            <th>15</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">16th - 20th</th>
            <th>7.5</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">21st - Last Place</th>
            <th>1.5</th>
          </tr>
        </tbody>
      </table>
  </div>
  </div>
  <div class="col-md-4 col-sm-12">
    <div class="table-responsive">
      <table class="table table-sm table-bordered">
        <thead class="table-dark">
          <tr>
            <th>Club Championship</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style="white-space: nowrap;">1st</th>
            <th>200</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">2nd</th>
            <th>150</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">3rd</th>
            <th>100</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">4th</th>
            <th>50</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">5th</th>
            <th>40</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">6th - 10th</th>
            <th>30</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">11th - 15th</th>
            <th>20</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">16th - 20th</th>
            <th>10</th>
          </tr>
          <tr>
            <th style="white-space: nowrap;">21st - Last Place</th>
            <th>2</th>
          </tr>
        </tbody>
      </table>
  </div>
  </div>
</div>
<div class="col-12">
  <ul>
    <li>Every match will have a longest drive and closest to the pin challange. The winner of these challanges will be awarded
      an additional 20 points for regular events, 30 points for majors and 40 points for club Championship.
    </li>
    <li>There will also be a $25 cash prize for regular events and $50 for the major/club championship. So practice hitting bombs and darts.</li>
  </ul>
</div>

-->