import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IPlayer } from 'src/app/models/viewModels';
import { AuthService } from 'src/app/services/auth.service';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-player-detail',
  templateUrl: './player-detail.component.html',
  styleUrls: ['./player-detail.component.css']
})
export class PlayerDetailComponent implements OnInit {
  playerForm: FormGroup;
  player: IPlayer;
  hasPlayer: boolean;

  constructor(private dataSvc: TkcDataService, private authSvc: AuthService, private fb: FormBuilder) { }

  ngOnInit(): void {
    const username = this.authSvc.getUserInfo().username;
    this.dataSvc.getPlayer(username).then((data) => {
      console.log(data);
      this.player = data[0];
      this.hasPlayer = true;
      this.initForm();
    }).catch((err) => {
      console.log(err);
    });
  }

  initForm(): void{
    this.playerForm = this.fb.group({
      firstName: [ {value: this.player.firstName, disabled: true }],
      lastName: [ {value: this.player.lastName, disabled: true }],
      email: [{value: this.player.email, disabled: true }],
      handicap: [{ value: this.player.handicap, disabled: true }]
    });
  }
}
