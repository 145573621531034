import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogHelperService {
  log(msg: string): void {
    console.log(msg);
  }
}
