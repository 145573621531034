import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { LoginUser } from '../../models/viewModels';
import { TkcDataService } from '../../services/tkc-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  invalidLogin = false;
  showPasswordSection = false;
  showCreatePassword = false;
  loginUser = new LoginUser();

  constructor(private fb: FormBuilder, private tkcSvc: TkcDataService, private authService: AuthService) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      newPassword: [''],
      confirmPassword: [''],
    });
  }

  verifyEmail(): void {
    if (this.loginForm.get('username').value != null) {
      const userEmail = this.loginForm.get('username').value;
      this.tkcSvc.checkEmail(userEmail).then((data) => {
        if (data) {
          this.showPasswordSection = data;
        } else {
          this.showCreatePassword = !data;
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  createPassword(): void {
    console.log('creating password');
    const password = this.loginForm.get('newPassword').value;
    const email = this.loginForm.get('username').value;
    this.tkcSvc.createPassword(email, password).then((data) => {
      if (data) {
        this.showPasswordSection = true;
        this.showCreatePassword = false;
      } else {
        this.showPasswordSection = true;
        this.showCreatePassword = false;
        console.log('false password creation');
        console.log(data);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  submit(): void {
    if (this.loginForm.valid) {
      console.log('form is valid');
      const user = { ...this.loginUser, ...this.loginForm.value };

      this.tkcSvc.login(user).then((data) => {
        if (data) {
          console.log(data);
          this.authService.setLoggedInPlayerInMenu(data);
        } else {
          this.invalidLogin = true;
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }

}
