<section id="section_hero" class="section_hero_left">
  <div class="heading-wrapper heading_left">
    <h3 class="heading-6">TKC GOLF CLUB</h3>
    <div class="hero_heading_wrapper">
      <h5 data-w-id="a79a0116-7a62-2dbf-7f79-cf81534179be" style="opacity:0" class="heading_left">CUP</h5>
      <h5 data-w-id="a79a0116-7a62-2dbf-7f79-cf81534179c0" style="opacity:0" class="heading_left lower">STANDINGS</h5>
    </div>
  </div>
  <img src="../../../assets/images/cup_staindings_hero.svg" loading="lazy" width="677" style="opacity:0" class="cup_image" alt="picture of the cup" data-w-id="942e88f1-ebf6-034e-7556-e494ea941ca1">
</section>

<section class="section_players standing-wrapper">
  <div data-w-id="3489bec9-840c-8b5d-318c-8931e4680e83" class="member_item" *ngFor='let player of tkcCupStandings | scoreSort: "points": "desc"; let indexOfelement=index'>
    <div class="member_first-data">
      <div class="member_item_hash">#{{indexOfelement + 1}}</div>
      <div class="member_player_data">
        <!--<img src="../../../assets/images/member_avatar.svg" loading="lazy" width="89" height="100" alt="" class="member_item_avatar">-->
        <div class="member_item_player">{{player.name}}</div>
      </div>
    </div>
    <div class="member_data">      
      <div class="member_item_pts-wrapper">
        <div class="member_item_label">Points</div>
        <div class="member_item_points">{{player.points}}</div>
      </div>
    </div>
    <div class="member_item-bg"></div>
  </div>
  
  
</section>

<section class="section_footer">
  <h4 class="footer-heading">Ready to hit<br><span class="colored-text">the Greens?</span></h4>
  <div class="center_content">
    <div class="space180"></div>
    <a href="/about#section_how_play" class="cta_primary white w-button">Join Us</a>
    <div class="space90"></div>
    <div class="container">
      <h3>contact</h3>
    </div>
  </div>
  <div class="big-email">TKCGOLFCLUB@GMAIL.COM</div>
  <div class="footer_bottom">
    <div>TKC golf club -  all rights reserved  2024</div>
  </div>
</section>
<!--
  
<div class="row pt-3">
  <div class="col-12"><h3>TKC Cup Standings</h3></div>
</div>
<div class="row">
  <div class="col-sm-6">
    <div class="table-responsive">
      <table class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            
            <th>Player</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let player of tkcCupStandings | scoreSort: "points": "desc";let indexOfelement=index'>
            
           
            <th style="white-space: nowrap;">{{player.name}}</th>
            <th>{{player.points}}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="row">
      <div class="col-12">
        <img src="/assets/images/tkc_trophy.jpg" class="rounded img-thumbnail" />
      </div>
    </div>
  </div>
</div>
-->
