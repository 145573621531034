import { Component, OnInit } from '@angular/core';
import { TkcDataService } from 'src/app/services/tkc-data.service';
import { ICourse } from '../../models/viewModels';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  constructor(
    private dataSvc: TkcDataService
  ) { }

  courses: ICourse[];
  hasCourses = false;

  ngOnInit(): void {
    this.dataSvc.getCourses().then((data) => {
      console.log(data);
      this.courses = data;
      this.hasCourses = true;
    }).catch((err) => {
      console.log(err);
    });
  }

}
