<div class="row">
  <div class="col-12">
    <h2 style="text-align: center;">How to join</h2>
  </div>
</div>

<!-- <div class="col-5">
    <img src="/assets/images/tkc_trophy.jpg" class="rounded img-fluid">
  </div> -->
<div class="row">
  <div class="col-12">
    <h4>What We Do</h4>
    <div>
      <ul>
        <li>We hold monthly tournaments, typically the 2nd or 3rd Saturday of the month.</li>
        <li>We are a handicap league. Our membership includes an SGCA membership and a GHIN</li>
        <li>We will play a 10 match season starting February and concluding in November</li>
        <li>Points are accrued each match as we all battle for the TKC Cup, as well as mid majors with cash prizes</li>
      </ul>
    </div>
  </div>
  <div class="col-12">
    <h4>Scoring and Competition</h4>
    <div>
      <ul>
        <li>We have a scoring chart on our website, but for example 1st place for each match is 100, 2nd 75, 3rd 50, etc. </li>
        <li>Each match will have a closest to the pin and longest drive challenge. Each of these will give the player 
          an additional 25 points to the day's match as well as a cash prizes</li>
        <li>There is a non mandatory $20.00 entry side pot each match</li>
        <li>There will be mid majors with larger side pots as prizes</li>
      </ul>
    </div>
  </div>
  <div class="col-12">
    <h4>Membership</h4>
    <div>
      <ul>
        <li>Membership is $71.00 and includes SCGA membership and GHIN</li>
        <li>To join, click <a href="https://membership.scga.org/start/join/?cid=8924" target="_blank">here</a></li>
      </ul>
    </div>
  </div>
</div>
