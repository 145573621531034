import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IPlayer } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-admin-member',
  templateUrl: './admin-member.component.html',
  styleUrls: ['./admin-member.component.css']
})
export class AdminMemberComponent implements OnInit {
  hasPlayers = false;
  players: IPlayer[];

  constructor(
    private dataSvc: TkcDataService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
    ) { }

  ngOnInit(): void {
    this.dataSvc.getPlayers().then((data) => {
      console.log(data);
      this.players = data;
      this.hasPlayers = true;
    }).catch((err) => {
      console.log(err);
    });
  }

  getPlayerStatusText(isActive: any): string {
    return isActive? 'Active' : 'Inactive';
  }

  addMember(): void {
    this.router.navigate(['../admin-member-create'], { relativeTo: this.route });
  }

  deletePlayer(event: any, playerId: string, content: any): void {
    if (event) {
      event.preventDefault();
    }
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      console.log(result);
      this.dataSvc.deletePlayer(playerId).then((data) => {
        this.players = data;
        this.hasPlayers = true;
      }).catch((err) => {
        console.log(err);
      });
    }, (reason) => {
      console.log(reason);
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
