
<section class="section_hero members-hero">
  <div class="heading-wrapper">
    <h3 class="teal">MEMBERS</h3>
    <h2 
    data-w-id="43e34914-a1da-33e5-883d-08a917d7534e" 
    style="opacity:0" 
    class="uppercase w70 teal">My worse day at golf <span class="colored-text">still beats my best day at work</span></h2>
  </div>
</section>

<section class="section_members" *ngIf='hasPlayers'>

  <div data-w-id="37218470-0859-d675-0f7e-e196b0dc4c30" class="member_item" *ngFor='let player of players  | playerSort: "lastName"; let i=index;'>
    <div class="member_first-data">
      <div class="member_item_hash">#{{i + 1}}</div>
      <div class="member_player_data">
        <!--<img src="../../../assets/images/member_avatar.svg" loading="lazy" width="89" height="100" alt="" class="member_item_avatar"> -->
        <div class="member_item_player">{{player.firstName}} {{player.lastName}}</div>
      </div>
    </div>
    <div class="member_data">
      <div class="member_item_pts-wrapper">
        <div class="member_item_label">GHIN#</div>
        <div class="member_item_points">{{player.ghin}}</div>
      </div>
      <div class="member_item_pts-wrapper">
        <div class="member_item_label">Handicap</div>
        <div class="member_item_points">{{player.handicap}}</div>
      </div>
    </div>
    <div class="member_item-bg"></div>
  </div>

</section>

<section class="section_footer">
  <h4 class="footer-heading">Ready to hit<br><span class="colored-text">the Greens?</span></h4>
  <div class="center_content">
    <div class="space180"></div>
    <a href="/about#section_how_play" class="cta_primary white w-button">Join Us</a>
    <div class="space90"></div>
    <div class="container">
      <h3>contact</h3>
    </div>
  </div>
  <div class="big-email">TKCGOLFCLUB@GMAIL.COM</div>
  <div class="footer_bottom">
    <div>TKC golf club -  all rights reserved  2024</div>
  </div>
</section>

<!--<div class="row"  *ngIf='hasPlayers'>
  <div class="col-12">
    <h1>Members</h1>
    <table class="table table-bordered table-striped table-hover table-sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>GHIN#</th>
          <th>Handicap</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let player of players  | playerSort: "lastName"'>
          <td>{{player.firstName}} {{player.lastName}}</td>
          <td>{{player.ghin}}</td>
          <td>{{player.handicap}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>-->
<div class="row" *ngIf='!hasPlayers'>
  <div class="spinner-border loader" role="status">
    <span class="sr-only loader">Loading...</span>
  </div>
</div>
