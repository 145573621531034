import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ICourse, IPlayer, IRound, ITournament, LoginUser } from '../models/viewModels';
import { ApiHelperService } from './api-helper.service';
import { LogHelperService } from './log-helper.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TkcApiService extends ApiHelperService {
  tkcApi = 'https://kimchi-curtido-golf-club.herokuapp.com';
  // tkcApi = 'http://localhost:8080';

  constructor(private http: HttpClient, public logger: LogHelperService, public authService: AuthService, private router: Router) {
    super(logger);
  }

  //#region PLAYER API CALLS
  createPlayer(player: IPlayer): Observable<boolean> {
    const tkcApiUrl = `${this.tkcApi}/createPlayer`;

    return this.http.post(tkcApiUrl, player).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        console.log(data);
        return data;
      })
    );
  }

  getPlayers(): Observable<any> {
    const tkcApiUrl = `${this.tkcApi}/getplayers`;
    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    );
  }

  getActivePlayers(): Observable<any> {
    const tkcApiUrl = `${this.tkcApi}/getActivePlayers`;
    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    );
  }

  getPlayerById(playerId: string): Observable<IPlayer> {
    const tkcApiUrl = `${this.tkcApi}/player/${playerId}`;
    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    );
  }

  getPlayer(username: string): Observable<IPlayer> {
    const tkcApiUrl = `${this.tkcApi}/Getplayers?email=${username}`;
    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    );
  }

  updatePlayer(player: IPlayer): Observable<IPlayer> {
    const tkcApiUrl = `${this.tkcApi}/updatePlayer`;

    return this.http.post(tkcApiUrl, player).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        console.log(data);
        return data;
      })
    );
  }

  deletePlayer(playerId: string): Observable<IPlayer[]> {
    const tkcApiUrl = `${this.tkcApi}/deletePlayer/${playerId}`;

    return this.http.delete(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        console.log(data);
        return data;
      })
    );
  }
  //#endregion

  //#region COURSE API CALLS
  createCourse(course: ICourse): Observable<ICourse> {
    const tkcApiUrl = `${this.tkcApi}/createCourse`;

    return this.http.post(tkcApiUrl, course).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        console.log(data);
        return data;
      })
    );
  }

  getCourses(): Observable<ICourse[]> {
    const tkcApiUrl = `${this.tkcApi}/GetCourses`;
    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    );
  }

  getCourse(courseId: string): Observable<ICourse> {
    const tkcApiUrl = `${this.tkcApi}/course/${courseId}`;
    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    );
  }

  updateCourse(course: ICourse): Observable<ICourse> {
    const tkcApiUrl = `${this.tkcApi}/updateCourse`;

    return this.http.post(tkcApiUrl, course).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        console.log(data);
        return data;
      })
    );
  }

  deleteCourse(courseId: string): Observable<ICourse[]> {
    const tkcApiUrl = `${this.tkcApi}/deleteCourse/${courseId}`;

    return this.http.delete(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        console.log(data);
        return data;
      })
    );
  }
  //#endregion

  //#region TOURNAMENT API CALLS
  createTournament(tournament: ITournament): Observable<ITournament[]> {
    const tkcApiUrl = `${this.tkcApi}/createTournament`;

    return this.http.post(tkcApiUrl, tournament).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        console.log(data);
        return data;
      })
    );
  }

  getTournaments(): Observable<any> {
    const tkcApiUrl = `${this.tkcApi}/GetTournaments/4`;

    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    );
  }

  getTournament(tournamentId: string): Observable<any> {
    const tkcApiUrl = `${this.tkcApi}/tournament/${tournamentId}`;

    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    );
  }

  getTournamentDetails(tournamentId: string): Observable<any> {
    const tkcApiUrl = `${this.tkcApi}/tournamentDetail/${tournamentId}`;

    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    );
  }

  updateTournament(tournament: ITournament): Observable<ITournament> {
    const tkcApiUrl = `${this.tkcApi}/updateTournament`;

    return this.http.post(tkcApiUrl, tournament).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        console.log(data);
        return data;
      })
    );
  }

  deleteTournament(tournamentId: string): Observable<any> {
    const tkcApiUrl = `${this.tkcApi}/deleteTournament/${tournamentId}`;

    return this.http.delete(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        console.log(data);
        return data;
      })
    );
  }
  //#endregion

  //#region ROUNDS API CALLS
  getCreateRoundData(): Observable<any> {
    const tkcApiUrl = `${this.tkcApi}/createRoundData`;
    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        return data;
      })
    );
  }

  createRound(round: IRound): Observable<IRound> {
    const tkcApiUrl = `${this.tkcApi}/createRound`;
    return this.http.post(tkcApiUrl, round).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    );
  }

  getRounds(): Observable<any> {
    const tkcApiUrl = `${this.tkcApi}/rounds`;
    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        return data;
      })
    );
  }

  getRound(roundId: string): Observable<any> {
    const tkcApiUrl = `${this.tkcApi}/round/${roundId}`;

    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          console.log(data);
        }
        return data;
      })
    );
  }

  updateRound(round: IRound): Observable<IRound> {
    const tkcApiUrl = `${this.tkcApi}/updateRound`;

    return this.http.post(tkcApiUrl, round).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        console.log(data);
        return data;
      })
    );
  }

  deleteRound(roundId: string): Observable<IRound[]> {
    const tkcApiUrl = `${this.tkcApi}/deleteRound/${roundId}`;

    return this.http.delete(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        console.log(data);
        return data;
      })
    );
  }
  //#endregion

  //#region STANDINGS API CALLS
  getTkcStandings(): Observable<any> {
    const tkcApiUrl = `${this.tkcApi}/getTkcStandings/4`;
    return this.http.get(tkcApiUrl).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
        }
        return data;
      })
    );
  }
  //#endregion

  //#region AUTH API CALLS
  login(user: LoginUser): Observable<any> {
    const tkcApiUrl = `${this.tkcApi}/signin`;
    return this.http.post(tkcApiUrl, user).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        if (data) {
          this.authService.setUserInfo({ user: data });
          this.router.navigate(['standings']);

        }
        return data;
      })
    );
  }

  checkEmail(userEmail: string): Observable<boolean> {
    const tkcApiUrl = `${this.tkcApi}/checkEmail`;
    return this.http.post(tkcApiUrl, { email: userEmail}).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        return data;
      })
    );
  }

  createPassword(email: string, password: string): Observable<boolean> {
    const tkcApiUrl = `${this.tkcApi}/createPassword`;
    return this.http.post(tkcApiUrl, { pw: password, email}).pipe(
      catchError((error) => this.handleError(error)),
      map((data) => {
        return data;
      })
    );
  }
  //#endregion
}
