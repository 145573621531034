<section class="section_tour_hero">
  <a (click)="gotToTournaments()" class="back_cta_link w-inline-block">
      <img src="../../../assets/images/back_arrow.svg" loading="lazy" width="69" alt="back arrow" class="back_cta"></a>
  <div class="tour_heading_wrapper z-0" #titleOutside>
    <div class="tour_head_name">{{tournament.tournamentName}}</div>
    <!--<div class="tour_head_name">CLASSIC</div>-->
  </div> 
  <div class="tour_hero_img_wrapper"></div>
  <div class="tour_heading_wrapper_alter" #titleInside>
    <div class="tour_head_name text-outline-white color_trans">{{tournament.tournamentName}}</div>
    <!--<div class="tour_head_name text-outline-white color_trans">CLASSIC</div>-->
  </div>
  <div class="tour_date">{{tournament.tournamentDate | date: 'fullDate'}}</div>
</section>
<div class="w-embed">
  <style>
.text-outline-black {
-webkit-text-stroke-width:1px;
-webkit-text-stroke-color: black;
-webkit-background-clip: text;
}
.text-outline-white {
-webkit-text-stroke-width:2px;
-webkit-text-stroke-color: white;
-webkit-background-clip: text;
-webkit-font-smoothing: subpixel-antialiased;
-webkit-background-clip: text;
}
.text-outline-green {
-webkit-background-clip: text;
-webkit-text-stroke-width:1px;
-webkit-text-stroke-color: #B2EB39;
text-shadow:
  -1px -1px 0 #B2EB39,
   0   -1px 0 #B2EB39,
   1px -1px 0 #B2EB39,
   1px  0   0 #B2EB39,
   1px  1px 0 #B2EB39,
   0    1px 0 #B2EB39,
  -1px  1px 0 #B2EB39,
  -1px  0   0 #B2EB39;
}
</style>
</div>


<section class="section_tour_grid">
  <h3 class="tour_grid_heading">TOURNAMENT RESULTS</h3>
  <div class="tour_grid_scroll">
    <div class="tour_grid_wrapper">
      <div class="tour_grid_row">
        <div class="tour_grid_cell"></div>
        <div class="tour_grid_cell cell_player">
          <div>Hole</div>
        </div>
        <ng-container *ngFor='let cardInfo of scoreCard.frontNine'>
          <div class="tour_grid_cell">
            <div>{{cardInfo.holeNumber}}</div>
          </div>
        </ng-container>        
        <div class="tour_grid_cell cell_col">
          <div>OUT</div>
        </div>
        <ng-container *ngFor='let cardInfo of scoreCard.backNine'>          
          <div class="tour_grid_cell">
            <div>{{cardInfo.holeNumber}}</div>
          </div>
        </ng-container>        
        <div class="tour_grid_cell cell_col cell_alter">
          <div>IN</div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div>Total</div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div>Gross Score</div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div>Index</div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div>Net<br>Score</div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div>TKC Points</div>
        </div>
      </div><!--ROW 1-->
      <div class="tour_grid_row">
        <div class="tour_grid_cell"></div>
        <div class="tour_grid_cell cell_player">
          <div class="bold">Blue</div>
        </div>
        <ng-container *ngFor='let cardInfo of scoreCard.frontNine'>          
          <div class="tour_grid_cell">
            <div>{{cardInfo.holeDistance}}</div>
          </div>
        </ng-container>
        <div class="tour_grid_cell cell_col">
          <div>{{scoreCard.GetDistanceOut}}</div>
        </div>
        
        <ng-container *ngFor='let cardInfo of scoreCard.backNine'>
          <div class="tour_grid_cell">
            <div>{{cardInfo.holeDistance}}</div>
          </div>
        </ng-container>
        <div class="tour_grid_cell cell_col cell_alter">
          <div>{{scoreCard.GetDistanceIn}}</div>
        </div>
        <div class="tour_grid_cell cell_col cell_alter">
          <div>{{scoreCard.GetDistanceTotal}}</div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div></div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div></div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div></div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div></div>
        </div>        
      </div><!-- ROW 2 -->
      <div class="tour_grid_row">
        <div class="tour_grid_cell"></div>
        <div class="tour_grid_cell cell_player">
          <div class="bold">Par</div>
        </div>
        <ng-container *ngFor='let cardInfo of scoreCard.frontNine'>
          <div class="tour_grid_cell">
            <div>{{cardInfo.holePar}}</div>
          </div>
        </ng-container>
        <div class="tour_grid_cell cell_col">
          <div>{{scoreCard.GetParOut}}</div>
        </div>
        <ng-container *ngFor='let cardInfo of scoreCard.backNine'>
          <div class="tour_grid_cell">
            <div>{{cardInfo.holePar}}</div>
          </div>
        </ng-container>        
        <div class="tour_grid_cell cell_col cell_alter">
          <div>{{scoreCard.GetParIn}}</div>
        </div>
        <div class="tour_grid_cell cell_col cell_alter">
          <div>{{scoreCard.GetParTotal}}</div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div></div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div></div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div></div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div></div>
        </div>        
      </div><!-- ROW 3 -->

      <div class="tour_grid_row" *ngFor='let round of rounds  | scoreSort: "place" : "asc";let indexOfelement=index; let even=even' [ngClass]="{ row_alter: even }"> 
        <div class="tour_grid_cell">
          <div>{{indexOfelement + 1}}</div>
        </div>
        <div class="tour_grid_cell cell_player">
          <div>{{round.playerFirstName}} {{round.playerLastName}}</div>
        </div>
        <div class="tour_grid_cell"  *ngFor='let holes of round.frontNine'>
          <div>{{holes.holeScore}}</div>
        </div>        
        <div class="tour_grid_cell cell_col">
          <div>{{round.GetRoundOut}}</div>
        </div>
        <div class="tour_grid_cell" *ngFor='let holes of round.backNine'>
          <div>{{holes.holeScore}}</div>
        </div>        
        <div class="tour_grid_cell cell_col cell_alter">
          <div>{{round.GetRoundIn}}</div>
        </div>
        <div class="tour_grid_cell cell_col_data cell_alter">
          <div>{{round.GetRoundTotal}}</div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div>{{round.GetRoundTotal - tournament.course.coursePar}}</div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div>{{round.playerHandicap != null? round.playerHandicap : 'NA'}}</div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div>{{round.GetNetScore}}</div>
        </div>
        <div class="tour_grid_cell cell_col_data">
          <div>{{round.tkcPoints}}</div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--
  
<div class="row">
  <div class="col-12">
    <b>Course:</b> 
    <div class="table-responsive">
      <table class="table table-sm table-bordered tksScoreCard">
        <thead class="thead-dark">
          <tr>
            <th></th>
            <th>Hole</th>
            <ng-container *ngFor='let cardInfo of scoreCard.frontNine'>
              <th class="d-none d-sm-table-cell">{{cardInfo.holeNumber}}</th>
            </ng-container>
            <th>OUT</th>
            <ng-container *ngFor='let cardInfo of scoreCard.backNine'>
              <th class="d-none d-sm-table-cell">{{cardInfo.holeNumber}}</th>
            </ng-container>
            <th>IN</th>
            <th>Total</th>
            <th>Gross Score</th>
            <th>Index</th>
            <th>Net Score</th>
            <th>TKC Points</th>
          </tr>
        </thead>
        <thead class="table-secondary">
          <tr>
            <th></th>
            <th>Blue</th>
            <ng-container *ngFor='let cardInfo of scoreCard.frontNine'>
              <th class="d-none d-sm-table-cell">{{cardInfo.holeDistance}}</th>
            </ng-container>
            <th>{{scoreCard.GetDistanceOut}}</th>
            <ng-container *ngFor='let cardInfo of scoreCard.backNine'>
              <th class="d-none d-sm-table-cell">{{cardInfo.holeDistance}}</th>
            </ng-container>
            <th>{{scoreCard.GetDistanceIn}}</th>
            <th>{{scoreCard.GetDistanceTotal}}</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <thead class="table-info">
          <tr>
            <th></th>
            <th>Par</th>
            <ng-container *ngFor='let cardInfo of scoreCard.frontNine'>
              <th class="d-none d-sm-table-cell">{{cardInfo.holePar}}</th>
            </ng-container>
            <th>{{scoreCard.GetParOut}}</th>
            <ng-container *ngFor='let cardInfo of scoreCard.backNine'>
              <th class="d-none d-sm-table-cell">{{cardInfo.holePar}}</th>
            </ng-container>
            <th>{{scoreCard.GetParIn}}</th>
            <th>{{scoreCard.GetParTotal}}</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let round of rounds  | scoreSort: "place" : "asc";let indexOfelement=index'>
            <th>{{indexOfelement + 1}}</th>
            <th style="white-space: nowrap;">{{round.playerFirstName}} {{round.playerLastName}}</th>
            <ng-container *ngFor='let holes of round.frontNine'>
              <th class="d-none d-sm-table-cell">{{holes.holeScore}}</th>
            </ng-container>
            <th class="table-secondary">{{round.GetRoundOut}}</th>
            <ng-container *ngFor='let holes of round.backNine'>
              <th class="d-none d-sm-table-cell">{{holes.holeScore}}</th>
            </ng-container>
            <th class="table-secondary">{{round.GetRoundIn}}</th>
            <th class="bg-info">{{round.GetRoundTotal}}</th>
            <th class="bg-info">{{round.GetRoundTotal - tournament.course.coursePar}}</th>
            <th>{{round.playerHandicap != null? round.playerHandicap : 'NA'}}</th>
            <th>{{round.GetNetScore}}</th>
            
            <th>{{round.tkcPoints}}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

-->