<div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
  <div class="container-2 w-container">
    <a href="index.html" aria-current="page" class="brand-2 w-nav-brand w--current"><img src="assets/images/tkc_logo.svg" loading="lazy" alt=""></a>
    <nav role="navigation" class="navmenu w-nav-menu">
      <a (click)="setActiveTab('home')"  [routerLink]="['/home']" aria-current="page" class="nav-link w-nav-link" [ngClass]="{'w--current': activeTab == 'home'}">Home</a>
      <a [ngClass]="{'w--current': activeTab == 'players'}" (click)="setActiveTab('players')" [routerLink]="['/players']" class="nav-link w-nav-link">members</a>
      <div (click)="toggleSubNav($event)" data-w-id="c3c7f18e-cbbc-d594-eec0-70373c26f464" class="nav-link-wrapper">
        <a href="#" class="nav-link w-nav-link">tournaments</a><img src="assets/images/menu-marker.svg" loading="lazy" width="12" height="11" alt="menu marker" class="image-4">
      </div>
      <div class="sub-navbar" #subNav>
        <a [ngClass]="{'w--current': activeTab == 'tournaments'}" (click)="setActiveTab('tournaments')" [routerLink]="['/tournaments']" class="nav-link sub-nav-link w-nav-link">TOURNAMENTS</a>
        <a [ngClass]="{'w--current': activeTab == 'rules'}" (click)="setActiveTab('rules')" [routerLink]="['/rules']" class="nav-link sub-nav-link w-nav-link">rules &amp; scoring</a>
        <a [ngClass]="{'w--current': activeTab == 'standings'}" (click)="setActiveTab('standings')" [routerLink]="['/standings']" class="nav-link sub-nav-link w-nav-link">cup standing</a>
      </div>
      <a [ngClass]="{'w--current': activeTab == 'about'}" (click)="setActiveTab('about')" [routerLink]="['/about']" class="nav-link w-nav-link">the club</a>
      <a [ngClass]="{'w--current': activeTab == 'admin'}" *ngIf="player?.isAdmin" (click)="setActiveTab('admin')" [routerLink]="['/admin']" class="nav-link w-nav-link">admin</a>
      <a href="#" class="nav-link w-nav-link" *ngIf="!isAuthenticated" (click)="toogleSignIn($event)">sign in</a>
      <div class="user-wrapper" *ngIf="isAuthenticated" (click)="toogleUserMenu($event)">
        Hello {{player.firstName}} <img src="assets/images/menu-marker-alter.svg"  loading="lazy" width="12" height="11" alt="menu marker" class="image-4">
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" #userProfileNav>
          <a class="dropdown-item" (click)="setActiveTab('players')" [routerLink]="['/playerDetails']">Player Profile</a>
          <a class="dropdown-item" (click)="signOut()" href="#">Sign Out</a>
        </div>
      </div>
    </nav>
    <div class="w-nav-button">
      <div class="w-icon-nav-menu"></div>
    </div>
  </div>
</div>
<section class="section_signin" [hidden]="!showSignInModal">
  <div class="siginin_content">
    <div class="asset_wrapper"></div>
    <div class="sigin_content">
      <h3 class="teal">TKC GOLF CLUB</h3>
      <div class="signin_title">SIGN IN</div>
      <!-- <div class="signin_copy">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div> -->
      <div class="form_modal w-form">
        
        <app-login></app-login>
        <div class="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div class="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
      <div class="signin_body_wrapper">
        <div class="signin_copy">You are not a member yet? no problem! </div>
        <a href="/about#section_how_play" class="w-inline-block">
          <div class="signin_copy">JOIN HERE</div>
        </a>
      </div>
      <span (click)="toogleSignIn($event)" class="close_icon w-inline-block"><img src="../../../assets/images/cose_icon.svg" loading="lazy" width="42" alt="close icon"></span>
    </div>
  </div>
</section>
<!--
<header>  
  <nav class="navbar navbar-expand-sm navbar-light fixed-top" style="background-color: #ffffff;">
    <a class="navbar-brand" href="#" *ngIf="!isMobile">
      <img src="/assets/images/tkc_cabbage_color.png" alt="" width="100" height="100" class="d-inline-block align-top">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="container">
      <div #navbarSupportedContent class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="nav navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 'home'}" (click)="setActiveTab('home')"  [routerLink]="['/home']">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 'players'}" (click)="setActiveTab('players')" [routerLink]="['/players']">Members</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 'tournaments'}" (click)="setActiveTab('tournaments')" [routerLink]="['/tournaments']">Tournaments</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 'standings'}" (click)="setActiveTab('standings')" [routerLink]="['/standings']">TKC Cup Standings</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 'rules'}" (click)="setActiveTab('rules')" [routerLink]="['/rules']">Rules & Scoring</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 'courses'}" (click)="setActiveTab('courses')" [routerLink]="['/courses']">Courses</a>
          </li>
          <li *ngIf="player?.isAdmin" class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 'admin'}" (click)="setActiveTab('admin')" [routerLink]="['/admin']">Admin</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 'howtojoin'}" (click)="setActiveTab('howtojoin')" [routerLink]="['/howtojoin']">Join</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 'about'}" (click)="setActiveTab('about')" [routerLink]="['/about']">About</a>
          </li>
        </ul>
        <div  *ngIf="!isAuthenticated"  class="d-flex">
          <a class="nav-link" *ngIf="!isAuthenticated" [routerLink]="['/login']">Signin</a>
        </div>
        <div *ngIf="isAuthenticated" class="d-flex">
          <ul class="nav navbar-nav mr-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Hello {{player.firstName}}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" (click)="setActiveTab('players')" [routerLink]="['/playerDetails']">Player Profile</a>
                <a class="dropdown-item" (click)="signOut()" href="#">Sign Out</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>-->
