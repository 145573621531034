<div class="row" id="admin-scorecards-header">
  <div class="row col-9">
    <div class="col-6">
      <h3>Rounds</h3>
    </div>
    <div class="col-6 text-right">
      <button  class="btn btn-info"  id="scores" (click)="addRound()">Add Round</button>
    </div>
  </div>
</div>
<div class="row"  *ngIf='hasRounds'>
  <div class="col-9">
    <table class="table table-bordered table-striped table-hover table-sm" id="grid-admin">
      <thead>
        <tr>
          <th>Player</th>
          <th>Tournament</th>
          <th>Score</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let round of rounds'>
          <td class="player-nl">{{round.playerName}}</td>
          <td>{{round.courseName}} - {{round.tournamentDate | date: 'shortDate'}}</td>
          <td>{{round.roundPar}}</td>
          <td align="center"><a class="alter-link" [routerLink]="['../admin-scorecard-edit', round.id]">Edit</a></td>
          <td align="center"><a class="alter-link" href="#" (click)="deleteRound($event, round.id, content)">Delete</a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-3">
  </div>
</div>
<div class="row admin-spinner" *ngIf='loading'>
  <div class="spinner-border loader" role="status">
    <span class="sr-only loader">Loading...</span>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Round</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span>Are you sure you want to delete this round</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('delte')">Delete</button>
  </div>
</ng-template>
