import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ICourse,
  IPlayer,
  ITournament, Round, ScoreCard
} from 'src/app/models/viewModels';

@Component({
  selector: 'app-tournament-detail',
  templateUrl: './tournament-detail.component.html',
  styleUrls: ['./tournament-detail.component.css']
})
export class TournamentDetailComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private router: Router,
    private route: ActivatedRoute
  ) { }

  tournament: ITournament;
  scoreCard: ScoreCard;
  rounds: Round[];
  players: IPlayer[];
  @ViewChild('titleOutside') titleOutside : ElementRef;
  @ViewChild('titleInside') titleInside : ElementRef;

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    let pos = (window.pageYOffset / 2) * -1;
    this.titleOutside.nativeElement.style.setProperty('transform',`translate(${[pos]}px, 0)`);
    this.titleInside.nativeElement.style.setProperty('transform',`translate(${pos}px, 0)`);
}

  gotToTournaments(event?: MouseEvent): void {
    if (event) { event.preventDefault(); event.stopPropagation(); }

    this.router.navigate(['/tournaments']);
  }

  ngOnInit(): void {
    this.document.body.classList.remove('body');
    this.document.body.classList.add('body_alter');
    const tournamentInfo = this.route.snapshot.data.tournamentData;
    this.tournament = tournamentInfo.tournament; // this.tkcSvc.getTournamentDetails(tournamentId);
    this.tournament.course = tournamentInfo.course[0];
    this.players = tournamentInfo.players;
    this.scoreCard = new ScoreCard(this.tournament.course.scoreCard);
    this.rounds = tournamentInfo.rounds.map((round) => {
      const player = this.players.filter((p) => {
        if (round.playerId === p._id) {
          return p;
        }
      })[0] as IPlayer;

      const roundObj = new Round(round, this.tournament.course.coursePar);
      roundObj.playerFirstName = player.firstName;
      roundObj.playerLastName = player.lastName;

      return roundObj;
    });
    // this.rounds = this.tkcSvc.getPlayerRounds(tournamentId);
  }
  
  ngOnDestroy(): void {
    this.document.body.classList.remove('body_alter');
    this.document.body.classList.add('body');
  }
}
