import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { AdminCourseCreateComponent } from './admin-courses/admin-course-create/admin-course-create.component';
import { AdminCourseEditComponent } from './admin-courses/admin-course-edit/admin-course-edit.component';
import { AdminCoursesComponent } from './admin-courses/admin-courses.component';
import { AdminMemberCreateComponent } from './admin-member/admin-member-create/admin-member-create.component';
import { AdminMemberEditComponent } from './admin-member/admin-member-edit/admin-member-edit.component';
import { AdminMemberComponent } from './admin-member/admin-member.component';
import {
  AdminCourseEditResolver,
  AdminMemberEditResolver,
  AdminScorecardCreateResolver,
  AdminScorecardEditResolver,
  AdminTournamentCreateResolver,
  AdminTournamentEditResolver
} from './admin-routing.service';
import { AdminScorecardCreateComponent } from './admin-scorecards/admin-scorecard-create/admin-scorecard-create.component';
import { AdminScorecardEditComponent } from './admin-scorecards/admin-scorecard-edit/admin-scorecard-edit.component';
import { AdminScorecardsComponent } from './admin-scorecards/admin-scorecards.component';
import { AdminTournamentCreateComponent } from './admin-tournaments/admin-tournament-create/admin-tournament-create.component';
import { AdminTournamentEditComponent } from './admin-tournaments/admin-tournament-edit/admin-tournament-edit.component';
import { AdminTournamentsComponent } from './admin-tournaments/admin-tournaments.component';
import { AdminComponent } from './admin.component';

const routes: Routes = [
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuardService],
    children: [
        { path: '',   redirectTo: 'admin-member', pathMatch: 'full', canActivate: [AuthGuardService]},
        { path: 'admin-member', component: AdminMemberComponent, canActivate: [AuthGuardService]},
        { path: 'admin-member-create', component: AdminMemberCreateComponent, canActivate: [AuthGuardService]},
        {
          path: 'admin-member-edit/:playerId',
          component: AdminMemberEditComponent,
          resolve: { player: AdminMemberEditResolver },
          canActivate: [AuthGuardService]
        },
        { path: 'admin-courses', component: AdminCoursesComponent, canActivate: [AuthGuardService]},
        { path: 'admin-course-create', component: AdminCourseCreateComponent, canActivate: [AuthGuardService]},
        {
          path: 'admin-course-edit/:courseId',
          component: AdminCourseEditComponent,
          resolve: { course: AdminCourseEditResolver },
          canActivate: [AuthGuardService]
        },
        { path: 'admin-tournaments', component: AdminTournamentsComponent, canActivate: [AuthGuardService]},
        {
          path: 'admin-tournament-create',
          component: AdminTournamentCreateComponent,
          resolve: { courses: AdminTournamentCreateResolver },
          canActivate: [AuthGuardService]
        },
        {
          path: 'admin-tournament-edit/:tournamentId',
          component: AdminTournamentEditComponent,
          resolve: { tournamentInfo: AdminTournamentEditResolver },
          canActivate: [AuthGuardService]
        },
        { path: 'admin-scorecards', component: AdminScorecardsComponent, canActivate: [AuthGuardService]},
        {
          path: 'admin-scorecard-create',
          component: AdminScorecardCreateComponent,
          resolve: { scorecardData: AdminScorecardCreateResolver },
          canActivate: [AuthGuardService]
        },
        {
          path: 'admin-scorecard-edit/:roundId',
          component: AdminScorecardEditComponent,
          resolve: { round: AdminScorecardEditResolver },
          canActivate: [AuthGuardService]
        },
        { path: '*', component: AdminComponent, canActivate: [AuthGuardService]},
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
