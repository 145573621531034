import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IPlayer } from 'src/app/models/viewModels';
import { TkcDataService } from 'src/app/services/tkc-data.service';

@Component({
  selector: 'app-admin-member-edit',
  templateUrl: './admin-member-edit.component.html',
  styleUrls: ['./admin-member-edit.component.css']
})
export class AdminMemberEditComponent implements OnInit {
  player: IPlayer;
  playerForm: FormGroup;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private dataSvc: TkcDataService
  ) { }

  ngOnInit(): void {
    this.player = this.route.snapshot.data.player;

    this.playerForm = this.fb.group({
      firstName: [ this.player.firstName ],
      lastName: [ this.player.lastName ],
      email: [ this.player.email ],
      ghin: [ this.player.ghin ],
      handicap: [ this.player.handicap ],
      isAdmin: [ this.player.isAdmin ],
      isActive: [ this.player.isActive ]
    });
  }

  updatePlayer(): void {
    if (this.playerForm.valid) {
      const player = {...this.player, ...this.playerForm.value };

      this.dataSvc.updatePlayer(player).then((data) => {
        this.router.navigate(['/admin/admin-member'], { relativeTo: this.route });
      }).catch((err) => {
        console.log(err);
      });
    }
  }
}
